import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import './../form-styles.css'
import {getTokenRequest, postTokenRequest, patchTokenRequest} from "../../../requests/requests";
import {FeedbackContext} from "../../../context/FeedbackContext";
import {useNavigate, useParams} from "react-router-dom";
import ImageField from "../fields/ImageField";
import Feedbacks from "../../feedback/Feedback";
import {validarExistencia, validarURL} from "../validadores";
import {Boton} from "../../boton/Botones";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {Imagen} from "../../imagenes/Imagenes";
import {EditarIcon, EliminarIcon} from "../../imagenes/Iconos";
import {HTML5Backend} from "react-dnd-html5-backend";
import update from "immutability-helper";
import ModalEmprendedora from "../../modals/ModalEmprendedora";

const ItemTypes = {
    TABLE_ROW: 'table_row',
}

const DraggableTableRow = ({index, content, moveRow, editRow, deleteRow}) => {
    const {id, posicion, imagen} = content
    const ref = useRef(null)
    const [, drop] = useDrop({
        accept: ItemTypes.TABLE_ROW, collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        }, hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const [, drag] = useDrag({
        type: ItemTypes.TABLE_ROW, item: () => {
            return {id, index}
        }, collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const proxyImagen = (imagen) => {
        if (imagen instanceof File) {
            return URL.createObjectURL(imagen)
        }
        return imagen
    }

    drag(drop(ref))

    return (<TableRow
        ref={ref}
        style={{
            cursor: "move",
        }}
    >
        <TableCell>
            <DragIndicatorIcon/>
        </TableCell>
        <TableCell>
            {posicion}
        </TableCell>
        <TableCell>
            <Imagen src={proxyImagen(imagen)}
                    width={"100%"} height={"100%"} aspectRatio="5/4"
                    style={{width: "100px"}}></Imagen>
        </TableCell>
        <TableCell>
            <div className={"flex"}>
                <EditarIcon onClick={() => editRow(id)}/>
                <EliminarIcon onClick={() => deleteRow(id, index)}/>
            </div>
        </TableCell>
    </TableRow>)
}

const DraggableTableRows = ({rows, moveRow, editRow, deleteRow}) => {

    return (<DndProvider backend={HTML5Backend}>
        {rows.map((row, i) => {
            return (<DraggableTableRow
                key={i}
                index={i}
                content={row}
                moveRow={moveRow}
                editRow={editRow}
                deleteRow={deleteRow}
            />)
        })}
    </DndProvider>)

}

export default function FormularioEmprendedora({accion}) {
    const navigate = useNavigate()
    const id = useParams().id;
    const {feedbackList, pushFeedback} = useContext(FeedbackContext);
    const [loaded, setLoaded] = useState(false);
    const [waitingForResponse, setWaitingForResponse] = useState(false);
    const [openModal, setOpenModal] = useState(false)

    const [formData, setFormData] = useState({
        nombres: '',
        apellidos: '',
        informacion: '',
        telefono: '',
        correo_electronico: '',
        fotografia: '',
        productos: []

    })

    const [formErrors, setFormErrors] = useState({
        nombres: '',
        apellidos: '',
        informacion: '',
        telefono: '',
        correo_electronico: '',
        fotografia: '',
        productos: '',
    })


    useEffect(() => {
            if (accion === 'editar') {
                getTokenRequest(`admin/emprendedoras/detalle/${id}/`).then((response) => {
                    setFormData(response.data);
                    setLoaded(true);
                }).catch(error => console.log(error))
            } else {
                setLoaded(true)
            }
        }, [accion, id]
    )


    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({...prevFormData, [name]: value}));
    };

    const handleFileChange = (event) => {
        event.preventDefault()
        let file = event.target.files.length > 0 ? event.target.files[0] : ''
        setFormData((prevFormData) => ({...prevFormData, [event.target.name]: file}));
    }

    const validarFormulario = () => {
        let valido = true

        let errors = {
            titulo: '',
            bajada: '',
            cuerpo: '',
            imagen: '',
        }


        let validacionTitulo = validarExistencia(formData.titulo);
        if (!validacionTitulo.valido) {
            errors.titulo = validacionTitulo.mensaje;
            valido = false;
        }

        if (!(formData.imagen instanceof File) && !(validarURL(formData.imagen).valido)) {
            errors.imagen = "Debe subir una imagen";
            valido = false
        }


        if (!valido) {
            window.scroll(0, 0)
            setFormErrors(errors)
        }
        return valido
    }

    const moveProductRow = useCallback((dragIndex, hoverIndex) => {
        let rows = update(formData.productos, {
            $splice: [[dragIndex, 1], [hoverIndex, 0, formData.productos[dragIndex]],],
        })
        rows.map((row, i) => {
            row.posicion = i + 1;
            return row;
        })
        setFormData({...formData, 'productos': rows})
    }, [formData])

    const handleSubmit = async (event) => {
        setWaitingForResponse(true)
        event.preventDefault();

        //let valido = validarFormulario()
        let valido = true


        if (valido && !waitingForResponse) {
            let data = new FormData();
            data.append('nombres', formData.nombres);
            data.append('apellidos', formData.apellidos);
            data.append('informacion', formData.informacion);
            data.append('correo_electronico', formData.correo_electronico);
            data.append('telefono', formData.telefono);

            if (formData.fotografia instanceof File) {
                data.append('fotografia', formData.fotografia);
            }

            if (accion === 'editar') {
                formData.productos.map((p, index) => {
                    if (p.imagen instanceof File) { //declaro tipo, pero mando todo c:
                        data.append(`productos[${index}]imagen`, p.imagen);
                    }
                    return null
                })


                await patchTokenRequest(`admin/emprendedoras/editar/${id}/`, data).then((response) => {
                        if (response.status === 200) {
                            pushFeedback("Emprendedora editada exitosamente")
                            navigate('/admin/emprendedoras/todas');
                        }
                    }
                ).catch(() => {
                    pushFeedback('Tuvimos un problema al editar la emprendedora', "error")
                    window.scroll(0, 0)
                });

            } else {
                formData.productos.map((p, index) => {
                    if (p.imagen instanceof File) {
                        data.append(`productos[${index}]imagen`, p.imagen);
                    }
                    return null
                })

                await postTokenRequest('/admin/emprendedoras/crear/', data).then((response) => {
                        if (response.status === 201) {
                            pushFeedback("Emprendedora creada exitosamente")
                            navigate('/admin/emprendedoras/todas');
                        }
                    }
                ).catch(() => {
                    pushFeedback('Tuvimos un problema al crear la emprendedora', "error")
                    window.scroll(0, 0)
                });
            }
        }
        setWaitingForResponse(false)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleConfirmModal = (file) => {
        let productos = formData.productos;
        productos.push({
            'imagen': file,
            'posicion': productos.length + 1
        })
        setFormData({...formData, 'productos': productos})
    }

    if (loaded) {
        return <>
            <Feedbacks feedbackList={feedbackList}/>
            <ModalEmprendedora open={openModal} onCloseModal={handleCloseModal} onConfirmModal={handleConfirmModal}/>
            {accion === 'editar' ? <h1>Editar emprendedora</h1> : <h1>Crear una emprendedora</h1>}
            {accion !== 'editar' ? <p>Para crear una emprendedora rellene los campos requeridos.</p> : null}
            <div className={"formulario"}>
                <h2>1. Información de la emprendedora</h2>
                <p><i>Rellene los campos con la información requerida de la emprendedora.</i></p>
                <form onSubmit={handleSubmit}>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>Nombres:</span> <span className={"rojo"}>*</span></label>
                        <div className={"rojo"}>{formErrors.nombres}</div>
                        <input type="text" id="nombres" name="nombres" value={formData.nombres}
                               onChange={handleChange}/>
                    </div>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>Apellidos:</span> <span className={"rojo"}>*</span></label>
                        <div className={"rojo"}>{formErrors.apellidos}</div>
                        <input type="text" id="apellidos" name="apellidos" value={formData.apellidos}
                               onChange={handleChange}/>
                    </div>
                    <div className={'form-field descripcion'}>
                        <label htmlFor="fname">Informacion:</label>
                        <div className={"rojo"}>{formErrors.informacion}</div>
                        <textarea id="informacion" name="informacion" value={formData.informacion}
                                  onChange={handleChange}/>
                    </div>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>Correo electrónico:</span> <span
                            className={"rojo"}>*</span></label>
                        <div className={"rojo"}>{formErrors.correo_electronico}</div>
                        <input type="text" id="correo_electronico" name="correo_electronico"
                               value={formData.correo_electronico} onChange={handleChange}/>
                    </div>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>Número de teléfono:</span> <span
                            className={"rojo"}>*</span></label>
                        <div className={"rojo"}>{formErrors.telefono}</div>
                        <input type="text" id="telefono" name="telefono" value={formData.telefono}
                               onChange={handleChange}/>
                    </div>
                    <div className={'form-field'}>
                        <label htmlFor={"fname"}>Fotografía: <span className={"rojo"}>*</span></label>
                        <div className={"rojo"}>{formErrors.fotografia}</div>
                        <ImageField name="fotografia" src={formData.fotografia} onChange={handleFileChange}
                                    width={"180px"} aspectRatio="1/1"/>
                    </div>
                </form>
                <div>
                    <h2>2. Productos</h2>
                    <p><i>Añada las imagenes de los productos de la emprendedora.</i></p>
                    <Boton color={"boton-azul"} texto={"Añadir producto"} onClick={handleOpenModal}/>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Posicion</TableCell>
                                <TableCell>Imagen</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <DraggableTableRows
                                rows={formData.productos}
                                moveRow={moveProductRow}
                                editRow={null}
                                deleteRow={null}
                            />
                        </TableBody>
                    </Table>
                </div>

                <div className={"formulario form-actions"}>
                    <Boton color={"boton-verde"} texto={"Enviar"} onClick={handleSubmit}/>
                </div>
            </div>
        </>
    }
    return null
}
