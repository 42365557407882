import {Outlet} from "react-router-dom";
import '../../menus/sitio_admin/menu_lateral/menu-lateral.css'
import './sitio-admin-layout.css'
import React, {useContext, useEffect} from "react";
import MenuLateral from "../../menus/sitio_admin/menu_lateral/MenuLateral";
import {FeedbackProvider} from "../../../context/FeedbackContext";
import {AuthContext} from "../../../context/AuthContext";
import MenuSuperiorAdmin from "../../menus/sitio_admin/menu_superior/MenuSuperiorAdmin";

export default function SitioAdminLayout() {
    const secciones = [
        {
            permiso: ['Publicador', 'Administrador'],
            nombre: 'Inicio',
            subsecciones: [{
                nombre: 'Carrusel',
                path: 'inicio/carrusel'
            }]
        },
        {
            permiso: ['Publicador', 'Administrador'],
            nombre: 'Noticias',
            subsecciones: [
                {
                    nombre: 'Todas',
                    path: 'noticias/todas'
                }
            ]
        },
        {
            permiso: ['Publicador', 'Administrador'],
            nombre: 'Capsulas',
            subsecciones: [
                {
                    nombre: 'Todas',
                    path: 'capsulas/todas'
                },
            ]
        },

        {
            permiso: ['Administrador'],
            nombre: 'Usuarios',
            subsecciones: [
                {
                    nombre: 'Todos',
                    path: 'usuarios/todos'
                }]
        }
    ]
    const {getUserInfo, isAuthenticated, user} = useContext(AuthContext)

    useEffect(() => {
        getUserInfo()
    }, [getUserInfo]);

    if (isAuthenticated) {
        return <FeedbackProvider>
            <div className={"sitio-admin-layout-wrapper"}>
                <MenuSuperiorAdmin/>
                <div className={"flex"}>
                    <MenuLateral rol_usuario={user.rol} secciones={secciones}></MenuLateral>
                    <div className={"sitio-admin-contenido"}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </FeedbackProvider>
    }
}
