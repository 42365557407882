import React, {useState} from 'react';
import LogoMujerezas from "../../assets/mujerezas/logo.mujerezas.svg";
import './login-styles.css'
import {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {Imagen} from "../../components/imagenes/Imagenes";
import FormularioCambiarContrasena from "../../components/forms/login/FormularioCambiarContrasena";
import {getRequest} from "../../requests/requests";

export default function PaginaCambiarContrasena() {
    const token = useParams().token;
    const [waitingForResponse, setWaitingForResponse] = useState(true);
    const [tokenValidation, setTokenValidation] = useState(false)


    useEffect(() => {
        getRequest(`authentication/cambiar_contrasena/validar_link/${token}/`).then(() => {
                setTokenValidation(true)
            }
        ).catch(() => {
            setTokenValidation(false)
        }).finally(() =>
            setWaitingForResponse(false)
        )
    }, [token]);

    const renderContent = () => {
        if (waitingForResponse)
            return <>
                <h1>Validando Link</h1>
                <p>El link está siendo validado, por favor aguarde.</p>
            </>
        else {
            if (tokenValidation)
                return <>
                    <div className={"login-form-wrapper"}>
                        <FormularioCambiarContrasena token={token}/>
                    </div>
                </>
            else
                return <>
                    <h1>No encontrado</h1>
                    <p>El link ha caducado o no existe.</p>
                </>
        }
    }

    return (
        <div className={"login-page-wrapper"}>
            <div className={"login-page-content"}>
                <Imagen src={LogoMujerezas} className={"login-logo"}/>
                {renderContent()}
            </div>

        </div>
    )
}
