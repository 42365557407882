import React, {useContext, useEffect, useState} from "react";
import './../form-styles.css'
import {getTokenRequest, postTokenRequest, patchTokenRequest} from "../../../requests/requests";
import {FeedbackContext} from "../../../context/FeedbackContext";
import {useNavigate, useParams} from "react-router-dom";
import Feedbacks from "../../feedback/Feedback";
import ImageField from "../fields/ImageField";
import {Boton} from "../../boton/Botones";
import {validarURL} from "../validadores";

export default function FormularioCarruselInicio({accion}) {
    const navigate = useNavigate()
    const id = useParams().id;

    const getEditarPrefixURL = 'admin/inicio/carrusel/detalle/'
    const postCrearURL = 'admin/inicio/carrusel/anadir/'
    const patchEditarPrefixURL = 'admin/inicio/carrusel/editar/'

    const onSuccessRedirectPATH = '/admin/inicio/carrusel'
    const onSuccessCreateFeedbackMessage = "Imagen creada exitosamente"
    const onSuccessEditFeedbackMessage = "Imagen editada exitosamente"
    const onErrorCreateFeedbackMessage = "Tuvimos un problema al crear la imagen. Por favor revise el formulario."
    const onErrorEditFeedbackMessage = "Tuvimos un problema al editar la imagen. Por favor revise el formulario."


    const {feedbackList, pushFeedback} = useContext(FeedbackContext);
    const [loaded, setLoaded] = useState(false);
    const [pending, setPending] = useState(false)

    const [formData, setFormData] = useState({
        imagen: '',
        url_redirect: '',
    })

    const [formErrors, setFormErrors] = useState({
            imagen: '',
            url_redirect: '',
        }
    )


    useEffect(() => {
            if (accion === 'editar') {
                getTokenRequest(`${getEditarPrefixURL}${id}/`).then((response) => {
                    setFormData(response.data);
                    setLoaded(true);
                }).catch(error => console.log(error))
            } else {
                setLoaded(true)
            }
        }, [accion, id]
    )


    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({...prevFormData, [name]: value}));
    };

    const handleFileChange = (event) => {
        event.preventDefault()
        let name = event.target.name;
        let file = event.target.files.length > 0 ? event.target.files[0] : ''
        setFormData((prevFormData) => ({...prevFormData, [name]: file}));
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        setPending(true)
        let valido = true
        let errors = {
            imagen: '',
            url_redirect: '',
        }

        if (!(formData.imagen instanceof File) && !(validarURL(formData.imagen).valido)) {
            errors.imagen = "Debe subir una imagen";
            valido = false
        }

        if (formData.url_redirect) {
            if (!(validarURL(formData.url_redirect).valido) && (formData.url_redirect.trim().length !== 0)) {
                errors.url_redirect = "Debe ingresar una url válida (ej: https://...  )";
                valido = false
            }
        }

        setFormErrors(errors)
        if (valido && !pending) {
            let data = new FormData();

            if (formData.imagen instanceof File)
                data.append('imagen', formData.imagen);
            if (formData.url_redirect) {
                data.append('url_redirect', formData.url_redirect.trim());
            }


            if (accion === 'editar') {
                await patchTokenRequest(`${patchEditarPrefixURL}${id}/`, data).then((response) => {
                        if (response.status === 200) {
                            pushFeedback(onSuccessEditFeedbackMessage)
                            navigate(onSuccessRedirectPATH);
                        }
                    }
                ).catch(() => {
                    pushFeedback(onErrorCreateFeedbackMessage, 'error')
                });
            } else {
                await postTokenRequest(postCrearURL, data).then((response) => {
                        if (response.status === 201) {
                            pushFeedback(onSuccessCreateFeedbackMessage)
                            navigate(onSuccessRedirectPATH);
                        }
                    }
                ).catch(() => {
                        pushFeedback(onErrorEditFeedbackMessage, 'error')
                    }
                );
            }
        }
        setPending(false)
    }

    if (loaded) {
        return (
            <>
                <Feedbacks feedbackList={feedbackList}/>
                {accion === 'editar' ?
                    <h1>Editar imagen</h1> :
                    <><h1>Añadir una imagen</h1><p>Para añadir una imagen rellene los campos requeridos.</p></>}
                <form className={"formulario"} onSubmit={handleSubmit}>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>Imagen: </span><span className={"rojo"}>*</span></label>
                        <div className={"rojo"}>{formErrors.imagen}</div>
                        <ImageField name="imagen" src={formData.imagen} onChange={handleFileChange}
                                    aspectRatio={"5/2"}/>
                    </div>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>URL <br/> <i>Ingrese una dirección si desea que la imagen redirija al cliquear.</i></span></label>
                        <div className={"rojo"}>{formErrors.url_redirect}</div>
                        <input type="text" id="url_recirect" name="url_redirect" value={formData.url_redirect}
                               onChange={handleChange}/>
                    </div>
                    <div className={"formulario form-actions"}>
                        <Boton color={"boton-verde"} texto={"Enviar"} onClick={handleSubmit}/>
                    </div>
                </form>
            </>
        )
    }
    return null
}
