import React, {useContext, useState} from "react";
import './../form-styles.css'
import {postTokenRequest} from "../../../requests/requests";
import {FeedbackContext} from "../../../context/FeedbackContext";
import {useNavigate} from "react-router-dom";
import Feedbacks from "../../feedback/Feedback";
import {validarExistencia, validarPassword} from "../validadores";
import {Boton} from "../../boton/Botones";

export default function FormularioCambiarContrasena() {
    const navigate = useNavigate()

    const postURL = 'admin/usuarios/cambiar_contrasena/'

    const onSuccessRedirectPATH = '/admin/usuarios/todos'
    const onFormSuccessFeedbackMessage = "Su contraseña ha sido cambiada exitosamente"
    const onFormErrorFeedbackMessage = "Tuvimos un problema al cambiar la contraseña."

    const {feedbackList, pushFeedback} = useContext(FeedbackContext);
    const [waitingForResponse, setWaitingForResponse] = useState(false);

    const [formData, setFormData] = useState({
        new_password: '',
        new_password_confirmation: '',
        current_password: ''
    })

    const [formErrors, setFormErrors] = useState({
        new_password: '',
        current_password: ''
    })


    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({...prevFormData, [name]: value}));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let esValido = true
        let errors = {
            new_password: '',
        }

        const validacionNewPassword = validarPassword(formData.new_password, formData.new_password_confirmation)
        if (!validacionNewPassword.valido) {
            errors.new_password = validacionNewPassword.mensaje;
            esValido = false
        }

        const validacionCurrentPassword = validarExistencia(formData.current_password, formData.new_password_confirmation)
        if (!validacionCurrentPassword.valido) {
            errors.current_password = validacionCurrentPassword.mensaje;
            esValido = false
        }

        setFormErrors((prevState) => {
            return {...prevState, ...errors}
        })

        if (esValido && !waitingForResponse) {
            setWaitingForResponse(true)

            let data = new FormData();

            data.append('new_password', formData.new_password);
            data.append('new_password_confirmation', formData.new_password_confirmation);
            data.append('current_password', formData.current_password);

            await postTokenRequest(postURL, data).then((response) => {
                    if (response.status === 200) {
                        pushFeedback(onFormSuccessFeedbackMessage)
                        navigate(onSuccessRedirectPATH);
                    }
                }
            ).catch((error) => {
                if (error.status === 401) {
                    setFormErrors({formErrors, current_password: error.response.data})
                } else {
                    pushFeedback(onFormErrorFeedbackMessage, 'error')
                }
            })
            setWaitingForResponse(false)
        }
    }


    return (
        <>
            <Feedbacks feedbackList={feedbackList}/>
            <h1>Cambiar contraseña</h1>
            <p>Para cambiar su contraseña rellene los campos requeridos.</p>
            <form className={"formulario"} onSubmit={handleSubmit}>
                <div className={'form-field'}>
                    <label htmlFor="fname">
                        <span>Nueva contraseña *</span>
                        {formErrors.new_password ? <div className={"error"}>{formErrors.new_password}</div> : null}
                    </label>
                    <input type="password" id="new_password" name="new_password" value={formData.new_password}
                           onChange={handleChange}/>
                </div>
                <div className={'form-field'}>
                    <label htmlFor="fname"><span>Confirme su nueva contraseña *</span></label>
                    <input type="password" id="new_password_confirmation" name="new_password_confirmation"
                           value={formData.new_password_confirmation}
                           onChange={handleChange}
                           onPaste={(e) => e.preventDefault()}/>
                </div>
                <div className={'form-field'} style={{marginTop: "40px"}}>
                    <label htmlFor="fname"><span>Ingrese su contraseña actual *</span></label>
                    {formErrors.current_password ?
                        <div className={"error"}>{formErrors.current_password}</div> : null}
                    <input type="password" id="current_password" name="current_password"
                           value={formData.current_password}
                           onChange={handleChange}/>
                </div>
                <div className={"formulario form-actions"}>
                    <Boton color={"boton-verde"} texto={"Cambiar contraseña"} onClick={handleSubmit}/>
                </div>
            </form>

        </>
    )

}
