import React from 'react';
import FormularioUsuario from "../../../components/forms/usuarios/FormularioUsuario";

export default function PaginaAnadirUsuario() {

    return (
        <div className={"pagina-crear-usuario-wrapper"}>
            <div className={"formulario-wrapper"}>
                <FormularioUsuario/>
            </div>
        </div>
    )

}
