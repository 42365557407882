import React, {useEffect, useState} from 'react';
import './pagina-emprendedoras.css'
import EmprendedoraCard from "../../../components/cards/EmprendedoraCard";
import {BotonEmprendedora} from "../../../components/boton/Botones";
import {getRequest} from "../../../requests/requests";
import {Imagen} from "../../../components/imagenes/Imagenes";
import img_proximamente from "../../../assets/emprendedoras/proximamente.png";

const letras_botones = [
    {
        first: 'a',
        middle: 'b',
        last: 'c'
    },
    {
        first: 'd',
        middle: 'e',
        last: 'f'
    },
    {
        first: 'g',
        middle: 'h',
        last: 'i'
    },
    {
        first: 'j',
        middle: 'k',
        last: 'l'
    },
    {
        first: 'm',
        middle: 'n',
        last: 'ñ'
    },
    {
        first: 'o',
        middle: 'p',
        last: 'q'
    },
    {
        first: 'r',
        middle: 's',
        last: 't'
    },
    {
        first: 'u',
        middle: 'v',
        last: 'w'
    },
    {
        first: 'x',
        middle: 'y',
        last: 'z'
    },
]

export default function PaginaEmprendedoras() {

    const [active, setActive] = useState(0)
    const [emprendedoras, setEmprendedoras] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [letras, setLetras] = useState({
        first: 'a',
        middle: 'b',
        last: 'c'
    })
    const [count, setCount] = useState(0)


    useEffect(() => {
        getRequest(`emprendedoras/todas/${letras.first}/${letras.middle}/${letras.last}/`).then((response) => {
                let emprendedora_data = response.data.results
                emprendedora_data.sort((a, b) => {
                    return (`${a.nombres}${a.apellidos}`).toLowerCase().localeCompare((`${b.nombres}${b.apellidos}`).toLowerCase())
                });
                setEmprendedoras(emprendedora_data)
                setLoaded(true)
                setCount(response.data.count)
            }
        ).catch(() => {

        })
    }, [letras.first, letras.last, letras.middle])

    const handleClick = (event, letras) => {
        setActive(Number(event.target.id))
        setLetras(letras)
    }

    if (true) {
        if (false) {
            return (
                <div className={"pagina-wrapper pagina-emprendedoras"}>
                    <div className={"pagina-admin-emprendedoras-buttons-wrapper"}>
                        <div className={`pagina-emprendedoras-buttons`}>
                            {letras_botones.map((letras, index) => {
                                return (
                                    <BotonEmprendedora id={index} key={index}
                                                       texto={`${letras.first} - ${letras.last}`}
                                                       active={index === active}
                                                       onClick={(event) => handleClick(event, letras)}/>)
                            })}
                        </div>
                    </div>
                    {emprendedoras.length > 0 ?
                        emprendedoras.map((emprendedora, index) => (
                            <EmprendedoraCard key={index} emprendedora={emprendedora}/>
                        ))
                        :
                        <h2 className={"flex justify-center"}>Aún no existen emprendedoras disponibles</h2>
                    }
                </div>
            )
        }
        else {
            return (<div className={"pagina-wrapper pagina-emprendedoras"}>
                    <h1>PRÓXIMAMENTE</h1>
                    <p>Muy pronto podrás conocer el listado de las 40 liderezas de Pymes y empresas de la Región de
                        Tarapacá que integrarán el grupo de Red de Mujerezas. En este espacio te contaremos a qué se
                        dedica cada una de ellas, cuáles son sus negocios y cómo puedes encontrarlas para comprar sus
                        productos a través de redes sociales y/o de forma presencial.
                    </p>
                    <Imagen className={"imagen-emprendedoras-proximamente"} width={"100%"}
                            src={img_proximamente}></Imagen>
                </div>
            )
        }
    }
}

