import React from 'react';
import './pagina-crear-capsula.css'
import FormularioCapsula from "../../../components/forms/capsulas/FormularioCapsula";

export default function PaginaCrearCapsula() {

    return (
        <div className={"pagina-crear-capsula-wrapper"}>
            <div className={"formulario-wrapper"}>
                <FormularioCapsula/>
            </div>
        </div>
    )

}
