import React from 'react';
import LogoMujerezas from "../../assets/mujerezas/logo.mujerezas.svg";
import './login-styles.css'
import {Imagen} from "../../components/imagenes/Imagenes";
import FormularioRecuperarContrasena from "../../components/forms/login/FormularioRecuperarContrasena";

export default function PaginaRecuperarContrasena() {

    return (
        <div className={"login-page-wrapper"}>
            <div className={"login-page-content"}>
                <Imagen src={LogoMujerezas} className={"login-logo"}/>
                <div className={"login-form-wrapper"}>
                    <FormularioRecuperarContrasena/>
                </div>
            </div>
        </div>
    )
}
