import React from "react";
import './pagina-equipo.css'
import {Imagen} from "../../components/imagenes/Imagenes";
import imagen_equipo from "../../assets/equipo/equipo_comprimida.png";
import EquipoCards from "../../components/cards/EquipoCards";

export default function PaginaEquipo() {
    return (
        <div className={"pagina-wrapper pagina-equipo"}>
            <div className={"pagina-equipo-presentacion"}>
                <Imagen className={"pagina-equipo-imagen-principal"} src={imagen_equipo} width={"100%"} height={"100%"}/>
                <div className={"pagina-equipo-texto-principal"}>
                    <p>
                        Te invitamos a conocer al equipo del proyecto "Red de Mujerezas", un grupo diverso y
                        multidisciplinario de profesionales apasionados por el fortalecimiento, la transformación y
                        el desarrollo de los negocios liderados por mujeres en la región de Tarapacá. Nuestro equipo
                        se destaca por su experiencia en áreas como la innovación, el emprendimiento, la gestión de
                        proyectos, la investigación y la capacitación, lo que nos permitirá ofrecer un enfoque
                        integral y efectivo en todas nuestras actividades y programas.
                    </p>
                </div>
            </div>
            <div>
                <h2>NUESTRO EQUIPO</h2>
                <EquipoCards/>
            </div>
        </div>
    )
}
