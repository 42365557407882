import React, {useCallback, useContext, useEffect} from "react";
import './feedback.css'
import {Alert} from "@mui/material";
import {FeedbackContext} from "../../context/FeedbackContext";

export function Feedback({feedback}) {
    const id = `feedback-${feedback.id}`
    const contenido = feedback.contenido;
    const tipo = feedback.tipo
    const {removeFeedback} = useContext(FeedbackContext);

    const handleClose = useCallback(() => {
        removeFeedback(feedback)
    }, [feedback, removeFeedback])

    useEffect(() => {
            let closeTimer = setTimeout(() => handleClose(), 2000);
            return () => {
                clearTimeout(closeTimer);
            };
        },
        [handleClose]
    );


    if (tipo === 'success')
        return (
            <div id={id} className={"feedback-wrapper"}>
                <Alert severity="success" onClose={handleClose}>
                    {contenido}
                </Alert>
            </div>
        )
    if (tipo === 'error')
        return (
            <div id={id} className={"feedback-wrapper"}>
                <Alert severity="error" onClose={handleClose}>
                    {contenido}
                </Alert>
            </div>
        )
}


export default function Feedbacks({feedbackList}) {
    if (feedbackList.length > 0) {
        return (
            feedbackList.map((feedback, index) => {
                return <Feedback key={index} feedback={feedback}/>
            })
        )
    }
    return null
}
