import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../../context/FeedbackContext";
import Feedbacks from "../../../components/feedback/Feedback";
import {useNavigate} from "react-router-dom";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {EditarIcon, EliminarIcon} from "../../../components/imagenes/Iconos";
import {deleteTokenRequest, getTokenRequest} from "../../../requests/requests";
import Swal from "sweetalert2";
import {Boton} from "../../../components/boton/Botones";

export default function PaginaAdminUsuarios() {
    const navigate = useNavigate();
    const {feedbackList, pushFeedback} = useContext(FeedbackContext);

    const [usuarios, setUsuarios] = useState([])

    const getApiURL = 'admin/usuarios/todos/'
    let deleteApiURL = 'admin/usuarios/eliminar/'


    const editButtonPrefixPATH = '/admin/usuarios/editar/'
    const deleteUsuarioFeedbackMessage = "El usuario ha sido eliminado exitosamente"



    useEffect(() => {
        getTokenRequest(getApiURL).then((response) => {
                setUsuarios(response.data)
            }
        ).catch((error) => {
            console.log(error);
        })
    }, [setUsuarios]);

    const handleAnadirClick = () => {
        navigate('/admin/usuarios/anadir');
    }

    const handleEditarClick = (id) => {
        navigate(`${editButtonPrefixPATH}${id}`);
    }

    const deleteElement = (id) => {
        deleteTokenRequest(`${deleteApiURL}${id}/`).then((response) => {
            if (response.status === 204) {
                setUsuarios((elements) => elements.filter(element => element.id !== id));
                pushFeedback(deleteUsuarioFeedbackMessage)
            }
        }).catch(error => {
            console.log(error)
        });
    }

    const handleEliminarClick = (id) => {
        Swal.fire({
            title: "¿Está seguro que desea continuar?",
            text: `Al eliminar este usuario no se podrá recuperar la cuenta`,
            icon: "warning",
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then((result) => {
                if (result.isConfirmed) {
                    deleteElement(id)
                }
            }
        )
    }

    return (
        <div className={"pagina-admin-usuarios-wrapper"}>
            <Feedbacks feedbackList={feedbackList}/>
            <div className={"flex space-between align-center"}>
                <h1>Administrar Usuarios</h1>
                <Boton color={"boton-azul"} texto={"Añadir Usuario"} onClick={handleAnadirClick}/>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Rut</TableCell>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Correo electrónico</TableCell>
                            <TableCell>Rol</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usuarios.map((usuario, index) => (
                            <TableRow
                                key={index}
                            >
                                <TableCell>{usuario.rut}</TableCell>
                                <TableCell>{usuario.nombre} {usuario.apellido}</TableCell>
                                <TableCell>{usuario.correo_electronico}</TableCell>
                                <TableCell>{usuario.rol}</TableCell>
                                <TableCell>
                                    <div className={"flex"}>
                                        <EditarIcon onClick={() => handleEditarClick(usuario.id)}/>
                                        <EliminarIcon onClick={() => handleEliminarClick(usuario.id)}/>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
