import React, {useEffect, useState} from 'react';
import './noticia-card.css'
import {Imagen} from "../imagenes/Imagenes";
import {NavLink} from "react-router-dom";

export function HorizontalCard({titulo, texto, imagen, to}) {
    return (
        <div className={"card-wrapper"}>
            <Imagen className={"card-imagen"} src={imagen} width={"100%"}/>
            <div className={"card-contenido"}>
                <h2 className={"horizontal-card-titulo"}>
                    {titulo}
                </h2>
                <div className={"card-texto"} dangerouslySetInnerHTML={{__html: texto}}/>
                <button className={"card-boton-ver-mas"} style={{margin: "0 auto"}}>
                    <NavLink to={to} reloadDocument>
                        Ver más
                    </NavLink>
                </button>
            </div>
        </div>
    )
}

export function VerticalCard({titulo, texto, imagen, to}) {
    return (
        <div className={"card-wrapper"} style={{display: "block"}}>
            <Imagen className={"card-imagen"} src={imagen} width={"100%"}/>
            <div className={"card-contenido"}>
                <h2 className={"card-titulo"}>
                    {titulo}
                </h2>
                <div className={"card-texto"} dangerouslySetInnerHTML={{__html: texto}}/>
                <button className={"card-boton-ver-mas"}>
                    <NavLink to={to} reloadDocument>
                        Ver más
                    </NavLink>
                </button>
            </div>
        </div>
    )
}

export default function NoticiaCard({noticia}) {
    const titulo = noticia.titulo ? noticia.titulo : "";
    const bajada = noticia.bajada ? noticia.bajada : "";
    const imagen = noticia.imagen ? noticia.imagen : "";
    const to = noticia.id ? `/noticias/${noticia.id.toString()}` : null;


    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 768;
    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return <>
        {
            width >= breakpoint ?
                <HorizontalCard titulo={titulo} texto={bajada} imagen={imagen} to={to}/> :
                <VerticalCard titulo={titulo} texto={bajada} imagen={imagen} to={to}/>
        }
    </>
}
