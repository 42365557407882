import React from "react";
import './footer.css'
import InformacionProyecto from "./components/InformacionProyecto";
import MenuRedesSociales from "../components/menus/menu_redes_sociales/MenuRedesSociales";

export default function Footer() {
    return (
        <footer className={"footer"}>
            <div className={"footer-wrapper content-wrapper"}>
                <InformacionProyecto/>
                <MenuRedesSociales/>
            </div>
        </footer>
    )
}
