import React, {useEffect, useState} from 'react'
import './pagina-inicio.css'
import ilus_mujerezas from '../../../assets/mujerezas/ilus_mujerezas.png'
import foto_mujerezas from '../../../assets/mujerezas/foto_mujerezas_comprimida.png'
import {Imagen} from "../../../components/imagenes/Imagenes";
import {CarruselInicio} from "../../../components/carruseles/carrusel_inicio/CarruselInicio";
import {VerticalCard} from "../../../components/cards/NoticiaCard";
import {getRequest} from "../../../requests/requests";
import CapsulaCard from "../../../components/cards/CapsulaCard";

export function PaginaInicioMobiles() {
    const [noticiasDestacas, setNoticiasDestacas] = useState([]);
    const [capsulasDestacadas, setCapsulasDestacadas] = useState([])

    useEffect(() => {
        getRequest('noticias/destacadas/').then((response) => {
                setNoticiasDestacas(response.data)
            }
        ).catch((error) => {
            console.log(error);
        })
    }, [setNoticiasDestacas]);

    useEffect(() => {
        getRequest('capsulas/destacadas/').then((response) => {
                setCapsulasDestacadas(response.data)
            }
        ).catch((error) => {
            console.log(error);
        })
    }, [setCapsulasDestacadas]);


    return (
        <>
            <CarruselInicio className={"carrusel-inicio"}/>
            <div className={"pagina-wrapper pagina-inicio"}>
                <div className={'pagina-inicio-bienvenidas'}>
                    <div>
                        <h2>HOLA,</h2>
                        <h1>BIENVENIDAS</h1>
                    </div>
                    <Imagen className={'pagina-inicio-imagen'} width={"100%"} src={ilus_mujerezas}
                            alt={"ilus_mujerezas"}/>
                    <div className={'pagina-inicio-presentacion'}>
                        <div>
                            <p>
                                Al espacio virtual “Red de Mujerezas”, una plataforma dedicada a todas las mujeres
                                emprendedoras y empresarias de Tarapacá que trabajan para profesionalizar sus negocios.
                            </p>
                            <p>En este espacio podrás conocer el perfil y el negocio de las mujeres que forman parte de
                                nuestra red. También podrás acceder a material educativo descargable, revisar nuestra
                                agenda
                                de actividades, informarte de las noticias relacionadas a nuestro programa y revisar los
                                registros audiovisuales de nuestros encuentros.
                            </p>
                        </div>

                    </div>
                </div>
                <div className={"pagina-inicio-mujerezas"}>
                    <div className={"pagina-inicio-mujerezas-texto"}>
                        <h1>MUJEREZAS</h1>
                    </div>
                </div>
            </div>
            <Imagen className={"pagina-inicio-mujerezas-foto"} src={foto_mujerezas} width={"100%"}/>
            <div className={"pagina-wrapper pagina-inicio"}>
                <div className={'pagina-inicio-mujerezas'}>
                    <p>
                        Nuestro objetivo es fortalecer el ecosistema de emprendimiento tarapaqueño, a través de la
                        transferencia de conocimiento, la innovación tecnológica, la gestión eficiente y el trabajo
                        en
                        red del ecosistema productivo tarapaqueño.
                    </p>
                </div>
                {noticiasDestacas.length ?
                    <div>
                        <h1>NOTICIAS DESTACADAS</h1>
                        <div className={"pagina-inicio-noticias-destacadas-card-wrapper"}>
                            {noticiasDestacas.map((noticia, index) => (
                                <div key={index} className={"pagina-inicio-noticia-destacada-card"}>
                                    <VerticalCard titulo={noticia.titulo}
                                                  texto={noticia.bajada}
                                                  imagen={noticia.imagen}/>
                                </div>
                            ))
                            }
                        </div>
                    </div> : null}
                {capsulasDestacadas.length ?
                    <div>
                        <h1>CÁPSULAS DESTACADAS</h1>
                        <div className={"capsulas-destacadas-card-wrapper"}>
                            {capsulasDestacadas.map((capsula, index) => (
                                <CapsulaCard key={index} capsula={capsula}/>
                            ))
                            }
                        </div>
                    </div> : null}
            </div>

        </>
    )
}

export function PaginaInicioDesktop() {
    const [noticiasDestacas, setNoticiasDestacas] = useState([]);
    const [capsulasDestacadas, setCapsulasDestacadas] = useState([])

    useEffect(() => {
        getRequest('noticias/destacadas/').then((response) => {
                setNoticiasDestacas(response.data)
            }
        ).catch((error) => {
            console.log(error);
        })
    }, [setNoticiasDestacas]);

    useEffect(() => {
        getRequest('capsulas/destacadas/').then((response) => {
                setCapsulasDestacadas(response.data)
            }
        ).catch((error) => {
            console.log(error);
        })
    }, [setCapsulasDestacadas]);
    return (
        <>
            <CarruselInicio className={"carrusel-inicio"}/>
            <div className={"pagina-wrapper pagina-inicio"}>
                <div className={'pagina-inicio-bienvenidas'}>
                    <div className={'pagina-inicio-presentacion'}>
                        <div>
                            <h2>HOLA,</h2>
                            <h1>BIENVENIDAS</h1>
                            <p>
                                Al espacio virtual “Red de Mujerezas”, una plataforma dedicada a todas las mujeres
                                emprendedoras y empresarias de Tarapacá que trabajan para profesionalizar sus negocios.
                            </p>
                            <p>En este espacio podrás conocer el perfil y el negocio de las mujeres que forman parte de
                                nuestra red. También podrás acceder a material educativo descargable, revisar nuestra
                                agenda
                                de actividades, informarte de las noticias relacionadas a nuestro programa y revisar los
                                registros audiovisuales de nuestros encuentros.
                            </p>
                        </div>
                    </div>
                    <div className={"pagina-inicio-imagen-wrapper"}>
                        <Imagen className={'pagina-inicio-imagen'} width={"100%"} src={ilus_mujerezas}
                                alt={"ilus_mujerezas"}/>
                    </div>
                </div>
                <div className={"pagina-inicio-mujerezas"}>
                    <Imagen className={"pagina-inicio-mujerezas-foto"} src={foto_mujerezas} width={"100%"}/>
                    <div className={"pagina-inicio-mujerezas-texto"}>
                        <h1> MUJEREZAS</h1>
                        <p>
                            Nuestro objetivo es fortalecer el ecosistema de emprendimiento tarapaqueño, a través de la
                            transferencia de conocimiento, la innovación tecnológica, la gestión eficiente y el trabajo
                            en
                            red del ecosistema productivo tarapaqueño.
                        </p>
                    </div>
                </div>
                {noticiasDestacas.length ? <div>
                    <h1>NOTICIAS DESTACADAS</h1>
                    <div className={"pagina-inicio-noticias-destacadas-card-wrapper"}>
                        {noticiasDestacas.map((noticia, index) => (
                            <div key={index} className={"pagina-inicio-noticia-destacada-card"}>
                                <VerticalCard
                                    titulo={noticia.titulo}
                                    texto={noticia.bajada}
                                    imagen={noticia.imagen}
                                    to={`noticias/${noticia.id}`}
                                />
                            </div>
                        ))
                        }
                    </div>
                </div> : null}
                {capsulasDestacadas.length ? <div>
                    <h1>CÁPSULAS DESTACADAS</h1>
                    <div className={"capsulas-destacadas-card-wrapper"}>
                        {capsulasDestacadas.map((capsula, index) => (
                            <CapsulaCard key={index} capsula={capsula}/>
                        ))
                        }
                    </div>
                </div> : null}
            </div>
        </>
    )
}

export default function PaginaInicio() {
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 768;
    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <>
            {
                width >= breakpoint ?
                    <PaginaInicioDesktop/> : <PaginaInicioMobiles/>
            }
        </>
    )
}
