import React from 'react';
import './pagina-proximamente.css'
import {Imagen} from "../../components/imagenes/Imagenes";
import img_proximamente from "./../../assets/proximamente/proximamente.png"

export default function PaginaProximamente() {
    return (
        <div className={"pagina-wrapper pagina-proximamente"}>
            <p>
                Las invitamos a permanecer conectadas y atentas a nuestras redes sociales donde anunciaremos el listado
                de
                Mujerezas que podrán profesionalizar sus negocios 🥰
            </p>
            <p>
                La próxima semana daremos a conocer el listado de mujeres beneficiadas que participarán de “Red De
                Mujerezas” 💜 lo cual nos pone muy contentas 🥳
            </p>
            <Imagen className={"imagen-proximamente"} width={"100%"} src={img_proximamente}></Imagen>
        </div>
    )
}
