import {LinkImagen} from "../../../imagenes/Imagenes";
import logo from "../../../../assets/mujerezas/logo.mujerezas.svg";
import React, {useContext} from "react";
import './menu-superior-admin.css'
import LogoutIcon from '@mui/icons-material/Logout';
import {AuthContext} from "../../../../context/AuthContext";
import {useNavigate} from "react-router-dom";


export default function MenuSuperiorAdmin() {
    const navigate = useNavigate();
    const {logout} = useContext(AuthContext);
    return (
        <menu className={"admin-menu-superior"}>
            <div className={'admin-menu-superior-image-wrapper'}>
                <LinkImagen className={'admin-menu-superior-image'} height={"100%"} to={""} src={logo}
                            alt={'logo_mujerezas'}/>
            </div>
            <div className={"admin-menu-superior-content"}>
                <div className={"admin-menu-superior-item"}><button onClick={() => navigate('/admin/cambiar_contrasena')}>Cambiar contraseña</button> </div>
                <div className={"admin-menu-superior-item flex"}><button onClick={logout}><LogoutIcon/><span>Cerrar sesión</span></button>
                </div>
            </div>

        </menu>
    )
}
