import update from 'immutability-helper'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useRef} from 'react'
import {DndProvider, useDrag, useDrop} from 'react-dnd'
import {HTML5Backend} from "react-dnd-html5-backend";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {Imagen} from "../../../components/imagenes/Imagenes";
import {EditarIcon, EliminarIcon} from "../../../components/imagenes/Iconos";
import {useNavigate} from "react-router-dom";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
    getTokenRequest,
    putTokenRequest
} from "../../../requests/requests";
import Feedbacks from "../../../components/feedback/Feedback";
import {FeedbackContext} from "../../../context/FeedbackContext";
import Swal from "sweetalert2";
import {Boton} from "../../../components/boton/Botones";

const ItemTypes = {
    TABLE_ROW: 'table_row',
}

const DraggableTableRow = ({index, content, moveRow, editRow, deleteRow}) => {
    const {id, posicion, imagen, url_redirect} = content
    const ref = useRef(null)
    const [, drop] = useDrop({
        accept: ItemTypes.TABLE_ROW, collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        }, hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const [, drag] = useDrag({
        type: ItemTypes.TABLE_ROW, item: () => {
            return {id, index}
        }, collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    drag(drop(ref))

    return (<TableRow
        ref={ref}
        style={{
            cursor: "move",
        }}
    >
        <TableCell>
            <DragIndicatorIcon/>
        </TableCell>
        <TableCell>
            {posicion}
        </TableCell>
        <TableCell>
            <Imagen src={imagen}
                    width={"100%"} height={"100%"} aspectRatio="5/2"
                    style={{width: "100px"}}></Imagen>
        </TableCell>
        <TableCell>{url_redirect}</TableCell>
        <TableCell>
            <div className={"flex"}>
                <EditarIcon onClick={() => editRow(id)}/>
                <EliminarIcon onClick={() => deleteRow(id, index)}/>
            </div>
        </TableCell>
    </TableRow>)
}

const DraggableTableRows = ({rows, moveRow, editRow, deleteRow}) => {

    return (<DndProvider backend={HTML5Backend}>
        {rows.map((row, i) => {
            return (<DraggableTableRow
                key={row.id}
                index={i}
                content={row}
                moveRow={moveRow}
                editRow={editRow}
                deleteRow={deleteRow}
            />)
        })}
    </DndProvider>)

}

export default function PaginaAdminInicioCarrusel() {
    const navigate = useNavigate();
    const [carruselItems, setCarruselItems] = useState([])
    const [updateFeedback, setUpdateFeedback] = useState(false)

    const {feedbackList, pushFeedback} = useContext(FeedbackContext);

    const getURL = 'admin/inicio/carrusel/todas/'
    const putURL = 'admin/inicio/carrusel/ordenar/'

    const createButtonPATH = '/admin/inicio/carrusel/anadir'
    const editButtonPrefixPATH = '/admin/inicio/carrusel/editar/'

    useEffect(() => {
        getTokenRequest(getURL).then((response) => {
            setCarruselItems(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }, [setCarruselItems]);


    const moveRow = useCallback((dragIndex, hoverIndex) => {
        let rows = update(carruselItems, {
            $splice: [[dragIndex, 1], [hoverIndex, 0, carruselItems[dragIndex]],],
        })
        rows.map((row, i) => {
            row.posicion = i + 1;
            return row;
        })
        setCarruselItems(rows)
    }, [carruselItems])

    const handleAnadirClick = () => {
        navigate(createButtonPATH);
    }

    const handleEditarClick = (id) => {
        navigate(`${editButtonPrefixPATH}${id}`);
    }

    const handleDeleteClick = (id, index) => {
        setCarruselItems(update(carruselItems, {
            $splice: [[index, 1]],
        }).map((row, i) => {
            row.posicion = i + 1;
            return row;
        }))
    }

    const handleSaveClick = async (event) => {
        event.preventDefault();

        Swal.fire({
            title: "¿Está seguro que desea continuar?",
            text: `Los elementos eliminados no podrán ser recuperados`,
            icon: "warning",
            confirmButtonText: 'Continuar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
                if (result.isConfirmed) {
                    await putTokenRequest(putURL, carruselItems).then((response) => {
                            if (response.status === 200) {
                                pushFeedback("Los cambios se han guardado exitosamente.")
                            }
                        }
                    ).catch(() => {
                            pushFeedback("Tuvimos un problema al guardar los cambios. Por favor inténtelo más tarde.", 'error')
                        }
                    ).finally(() =>
                        setUpdateFeedback(!updateFeedback)
                    );
                }
            }
        )
    }

    return (
        <>
            <Feedbacks feedbackList={feedbackList}/>
            <div className={"pagina-admin-carrusel-inicio-wrapper"}>
                <div className={"flex space-between align-center"}>
                    <h1>Carrusel página de inicio</h1>
                    <Boton color={"boton-azul"} texto={"Añadir Imagen"} onClick={handleAnadirClick}/>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Posicion</TableCell>
                            <TableCell>Imagen</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <DraggableTableRows
                            rows={carruselItems}
                            moveRow={moveRow}
                            editRow={handleEditarClick}
                            deleteRow={handleDeleteClick}
                        />
                    </TableBody>
                </Table>
                <div className={"flex justify-center"} style={{margin: "20px auto"}}>
                    <Boton color={"boton-verde"} texto={"Guardar Cambios"} onClick={handleSaveClick}/>
                </div>
            </div>
        </>
    )
}
