import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../../context/FeedbackContext";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {deleteTokenRequest, getTokenRequest, patchTokenRequest} from "../../../requests/requests";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import Feedbacks from "../../../components/feedback/Feedback";
import {DestacarIcon, EditarIcon, EliminarIcon} from "../../../components/imagenes/Iconos";
import {Boton} from "../../../components/boton/Botones";
import Paginacion from "../../../components/paginacion/Paginacion";
import './pagina-admin-capsulas.css'


export default function PaginaAdminCapsulas() {
    const navigate = useNavigate();
    const {feedbackList, pushFeedback} = useContext(FeedbackContext);
    const [capsulas, setCapsulas] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [page, setPage] = useState(1);
    const deleteCapsulaURL = 'admin/capsulas/eliminar/'
    const destacarCapsulaPrefixURL = 'admin/capsulas/destacar/'

    const deleteCapsulaFeedbackMessage = "La capsula ha sido eliminada exitosamente"

    const editButtonPrefixPATH = '/admin/capsulas/editar/'
    const createButtonPATH = '/admin/capsulas/crear'


    useEffect(() => {
        getTokenRequest(`admin/capsulas/todas/?pagina=${page}`).then((response) => {
                setCapsulas(response.data.results)
                setNumberOfPages(Math.ceil(response.data.count / 5))
            }
        ).catch((error) => {
            console.log(error);
        })
    }, [page, setCapsulas]);


    const handleCrearClick = () => {
        navigate(createButtonPATH);
    }

    const handleEditarClick = (id) => {
        window.location.href = `${editButtonPrefixPATH}${id}`;
    }

    const deleteCapsula = (id) => {
        deleteTokenRequest(`${deleteCapsulaURL}${id}/`).then((response) => {
            if (response.status === 204) {
                setCapsulas((elements) => elements.filter(element => element.id !== id));
                pushFeedback(deleteCapsulaFeedbackMessage)
            }
        }).catch(error => {
            console.log(error)
        });
    }

    const handleEliminarClick = (id) => {
        Swal.fire({
            title: "¿Está seguro que desea continuar?",
            text: `Al eliminar esta cápsula no podrá recuperarla`,
            icon: "warning",
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then((result) => {
                if (result.isConfirmed) {
                    deleteCapsula(id)
                }
            }
        )
    }

    const handleDestacarClick = (id, destacadaStatus) => {
        let data = new FormData();
        data.append('destacada', destacadaStatus);

        patchTokenRequest(`${destacarCapsulaPrefixURL}${id}/`, data).then((response) => {
            if (response.status === 200) {
                let updatedElements = capsulas.map(element => {
                    if (element.id === id) {
                        element.destacada = destacadaStatus
                    }
                    return element
                })
                setCapsulas(updatedElements);
            }
        }).catch(error => {
            console.log(error)
        });
    }

    const handlePageChange = (event, page) => {
        setPage(page);
        window.scroll(0, 0)
    }

    return (
        <div className={"pagina-admin-noticias-wrapper"}>
            <Feedbacks feedbackList={feedbackList}/>
            <div className={"flex space-between align-center"}>
                <h1>Administración de Cápsulas</h1>
                <Boton color={"boton-azul"} texto={"Nueva Cápsula"} onClick={handleCrearClick}/>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Video</TableCell>
                            <TableCell>Titulo</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {capsulas.map((capsula, index) => (
                            <TableRow
                                key={index}
                            >
                                <TableCell>
                                    <video style={{
                                        width: "100px",
                                        height: "56px",
                                        aspectRatio: "16/9",
                                        backgroundColor: "black"
                                    }} src={capsula.archivo}/>
                                </TableCell>
                                <TableCell>{capsula.titulo}</TableCell>
                                <TableCell>
                                    <div className={"flex"}>
                                        <DestacarIcon destacado={capsula.destacada}
                                                      onClick={() => handleDestacarClick(capsula.id, !capsula.destacada)}/>
                                        <EditarIcon onClick={() => handleEditarClick(capsula.id)}/>
                                        <EliminarIcon onClick={() => handleEliminarClick(capsula.id)}/>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={"pagina-capsulas-paginacion-wrapper"}>
                <Paginacion numberOfPages={numberOfPages} page={page} onChange={handlePageChange}/>
            </div>
        </div>
    )
}
