import React, {useEffect, useState} from 'react';
import './pagina-noticia-detalle.css'
import {Imagen} from "../../../components/imagenes/Imagenes";
import {useParams} from "react-router-dom";
import {getRequest} from "../../../requests/requests";

export default function PaginaNoticiaDetalle({accion}) {
    const id = useParams().id;
    const [noticia, setNoticia] = useState(
        {titulo: "", bajada: "", cuerpo: "", imagen: "",})

    useEffect(() => {
        if (accion === 'previsualizar') {
            let retrievedNoticia = localStorage.getItem('previewNoticia')
            if (retrievedNoticia) {
                setNoticia(JSON.parse(retrievedNoticia))
            }
            localStorage.removeItem('previewNoticia')
        } else {
            getRequest(`noticias/detalle/${id}/`).then((response) => {
                    setNoticia(response.data)
                }
            ).catch((error) => {
                console.log(error);
            })
        }
    }, [accion, setNoticia, id]);

    return (
        <div className={"pagina-wrapper"}>
            <div className={"noticia-detalle"}>
                <div className={"noticia-titulo"}>
                    <h1>
                        {noticia.titulo}
                    </h1>
                </div>
                <Imagen className={"noticia-imagen"} src={noticia.imagen} width={"100%"}/>
                <div className={"noticia-bajada"}
                     dangerouslySetInnerHTML={{__html: noticia.bajada}}/>
                <div className={"noticia-cuerpo"}
                     dangerouslySetInnerHTML={{__html: noticia.cuerpo}}/>

            </div>
        </div>
    )
}
