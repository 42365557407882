import {Outlet} from "react-router-dom";
import Header from "../../../header/sitio_principal/Header";
import Footer from "../../../footer/Footer";


export default function SitioPrincipalLayout() {
    return (
        <>
            <Header/>
            <div className="content-wrapper">
                <Outlet/>
            </div>
            <Footer/>
        </>
    )
}
