const calcularDigitoVerificador = (rut) => {
    let digitos = rut.replace(/[^0-9Kk]/g, '')
    let cuerpo = digitos.substring(0, digitos.length - 1)

    let cuerpo_reverso = cuerpo.split("").map((digito) => {
        return parseInt(digito, 10)
    }).reverse()
    let serie = [2, 3, 4, 5, 6, 7]

    let suma = 0
    for (let i = 0; i < cuerpo_reverso.length; i++) {
        suma += cuerpo_reverso[i] * serie[i % serie.length]
    }

    let digito = (11 - (suma % 11)).toString()

    if (digito === "10")
        digito = "k"
    else if (digito === "11")
        digito = "0"

    return digito
}

export const validarExistencia = (input) => {
    let validacion = {valido: true, mensaje: ''};
    if (!input.trim()) {
        validacion = {valido: false, mensaje: "Este campo es requerido"}
    }
    return validacion
}

export const validarLargo = (input) => {
    let validacion = {valido: true, mensaje: ''};
    if (input.length < 8) {
        validacion = {valido: false, mensaje: "Debe tener a lo menos 8 caracteres"}
    }
    return validacion
}

export const validarPassword = (password1, password2) => {

    let validacion = {valido: true, mensaje: ''};

    let validacionExistencia = validarExistencia(password1)
    if (!validacionExistencia.valido) {
        validacion = validacionExistencia
        return validacion
    }

    let validacionLargo = validarLargo(password1);
    if (!validacionLargo.valido) {
        validacion = validacionLargo
        return validacion
    }

    if (password1 !== password2) {
        validacion = {valido: false, mensaje: "Las contraseñas deben coincidir"}
    }
    return validacion
}


export const validarRut = (rut) => {
    let validacion = {valido: true, mensaje: ''};

    rut = rut.toLowerCase()

    let validacionExistencia = validarExistencia(rut)
    if (!validacionExistencia.valido) {
        validacion = validacionExistencia
        return validacion
    }

    const regexpRut = /^\d{1,2}[.]?\d{3}[.]?\d{3}[-]?[\dkK]$/
    let validacionFormato = regexpRut.test(rut)
    if (!validacionFormato) {
        validacion = {valido: false, mensaje: "El rut ingresado no es válido"}
        return validacion
    }

    let digitoVerificador = calcularDigitoVerificador(rut)
    let ultimoDigitoRut = rut.charAt(rut.length - 1)
    if (digitoVerificador !== ultimoDigitoRut) {
        validacion = {valido: false, mensaje: "El digito verificador es incorrecto"}
        return validacion
    }
    return validacion;
}

export const formatearRut = (rut) => {
    let digitos = rut.replace(/[^0-9Kk]/g, '').toLowerCase();

    if (digitos.length <= 1)
        return digitos

    let cuerpo = digitos.substring(0, digitos.length - 1).replace(/\D/g, '')
    let dv = digitos.charAt(digitos.length - 1)
    let cuerpo_reverso = cuerpo.split("").reverse().join("")
    let cuerpo_reverso_formateado = ''
    for (let i = 0; i < cuerpo_reverso.length; i++) {
        if (i % 3 === 0 && i > 0) {
            cuerpo_reverso_formateado = cuerpo_reverso_formateado + '.' + cuerpo_reverso[i]
        } else {
            cuerpo_reverso_formateado += cuerpo_reverso[i]
        }
    }

    let cuerpo_formateado = cuerpo_reverso_formateado.split("").reverse().join("")

    return cuerpo_formateado + '-' + dv
}

export const validarURL = (input) => {
    let validacion = {valido: true, mensaje: ''};
    try{
        new URL(input);
    } catch (e){
        validacion = {valido: false, mensaje: 'El link no es válido'};
    }
    return validacion
}

export const validarEmail = (email) => {
    let validacion = {valido: true, mensaje: ''};

    let validacionExistencia = validarExistencia(email)
    if (!validacionExistencia.valido) {
        validacion = validacionExistencia
        return validacion
    }

    const regexpEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    let validacionFormato = regexpEmail.test(email)
    if (!validacionFormato) {
        validacion = {valido: false, mensaje: "El correo electrónico ingresado no es válido"}
        return validacion
    }

    return validacion
}
