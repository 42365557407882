import React from "react";
import './menu-redes-soclales.css'
import logo_facebook from './../../../assets/redes_sociales/logo_facebook.svg'
import logo_instagram from './../../../assets/redes_sociales/logo_instagram.svg'
import logo_youtube from './../../../assets/redes_sociales/logo_youtube.svg'
import logo_spotify from './../../../assets/redes_sociales/logo_spotify.svg'
import {LinkImagen} from "../../imagenes/Imagenes";

export default function MenuRedesSociales() {
    const logo_width = "50px";
    const logo_height = "50px"

    const facebook_mujerezas = "https://web.facebook.com/profile.php?id=61560928860591"
    const instagram_mujerezas = "https://www.instagram.com/red_mujerezas/"
    const youtube_mujerezas = "https://www.youtube.com/channel/UCRmLTlif4XNZtDhiElNq_mg"
    const spotify_mujerezas = "https://open.spotify.com/playlist/3imTznHXHnmmxNkQ4cuVys?si=NTBXEvFHS4WwNdpIS4v4ow&dd=1&nd=1&fbclid=IwAR2dVKYTD0gbu_qxJfRNJKJuKaNEAQsifSVTIe1lPAk26VirDwa1dFz65gw&_branch_match_id=974644009378437127&utm_medium=sharing&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXLy7IL8lMq9TLyczL1vd2ds7PjwoPCK1Msk9LSs7JTLH1LHcMMkoJ844McTFITyqNL6zwSgvy8%2FL2KvVO9HN1DCzOTAsOC%2FFMNcwJcMw2MgvLLHIpTzRMcasyM00vBwBziUQrZQAAAA%3D%3D"

    return (
        <div className={"menu-redes-sociales-wrapper"}>
            <div>NUESTRAS REDES</div>
            <div className={"logos-redes-sociales-wrapper"}>
                <LinkImagen to={facebook_mujerezas} target="_blank" src={logo_facebook}
                            alt={"logo_facebook"} width={logo_width} height={logo_height}/>
                <LinkImagen to={instagram_mujerezas} target="_blank"  src={logo_instagram} alt={"logo_instagram"}
                            width={logo_width} height={logo_height}/>
                <LinkImagen to={youtube_mujerezas} target="_blank"  src={logo_youtube}
                            alt={"logo_youtube"} width={logo_width} height={logo_height}/>
                <LinkImagen to={spotify_mujerezas} target="_blank"
                            src={logo_spotify} alt={"logo_spotify"} width={logo_width} height={logo_height}/>
            </div>
        </div>
    )
}
