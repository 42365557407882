import axios from "axios";
import Cookies from 'js-cookie'

axios.defaults.withCredentials = true

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/`
})


instance.interceptors.response.use(
    async (response) => {
        return response
    },
    async (error) => {
        if (error && error.status === 403) {
            if (!await isAuthenticated()) {
                window.location.href = '/login';
            }
        }
        return Promise.reject(error)
    },
);

export const isAuthenticated = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/authentication/is_authenticated/`, {withCredentials: true})
        .then(() => true)
        .catch(() => false)
}

export const getRequest = async (url) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/${url}`)
}

export const postTokenRequest = async (url, formData, config) => {
    let defaultConfig = {
        withCredentials: true,
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
        }
    }
    return await instance.post(`${url}`, formData, {...config, ...defaultConfig})
}

export const getTokenRequest = async (url) => {
    let defaultConfig = {
        withCredentials: true,
    }
    return await instance.get(`${url}`, defaultConfig)
}

export const putTokenRequest = async (url, formData, config) => {
    let defaultConfig = {
        withCredentials: true,
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
        }
    }
    return await instance.put(`${url}`, formData, {...config, ...defaultConfig})
}
export const patchTokenRequest = async (url, formData, config) => {
    let defaultConfig = {
        withCredentials: true,
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
        }
    }
    return await instance.patch(`${url}`, formData, {...config, ...defaultConfig})
}

export const deleteTokenRequest = async (url) => {
    let defaultConfig = {
        withCredentials: true,
        headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
        }
    }
    return await instance.delete(`${url}`, defaultConfig)
}
