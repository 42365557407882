import React, {useEffect, useState} from 'react';
import './pagina-capsulas.css'
import {getRequest} from "../../../requests/requests";
import CapsulaCard from "../../../components/cards/CapsulaCard";
import Paginacion from "../../../components/paginacion/Paginacion";
import {useParams} from "react-router-dom";

export default function PaginaCapsulas() {
    const [capsulas, setCapsulas] = useState([])
    const [numberOfPages, setNumberOfPages] = useState(1);
    const pagina = parseInt(useParams().pagina)
    const [page] = useState(pagina ? pagina : 1);

    useEffect(() => {
        getRequest(`capsulas/todas/?pagina=${page}`).then((response) => {
                setCapsulas(response.data.results)
                setNumberOfPages(Math.ceil(response.data.count / 5))
            }
        ).catch((error) => {
            console.log(error);
        })
    }, [page, setCapsulas]);

    const handlePageChange = (event, pageNumber) => {
        window.location.href = (`/capsulas/pagina/${pageNumber}`);
    }

    return (
        <div className={"pagina-wrapper pagina-capsulas"}>
            <h1>Videos</h1>
            <div className={"capsulas-wrapper"}>
                {
                    capsulas.map((capsula, index) => (
                        <CapsulaCard key={index} capsula={capsula}/>
                    ))
                }
            </div>
            <div className={"pagina-capsulas-paginacion-wrapper"}>
                <Paginacion numberOfPages={numberOfPages} page={page} onChange={handlePageChange}/>
            </div>
        </div>
    )
}
