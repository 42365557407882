import React, {useEffect, useState} from 'react';
import './equipo-cards.css'
import {Imagen} from "../imagenes/Imagenes";
import img_raul_castillo from "../../assets/equipo/raul_castillo.jpg"
import img_sandra_gonzalez from "../../assets/equipo/sandra_gonzalez.jpg"
import img_sheyla_etcheverry from "../../assets/equipo/sheyla_etcheverry.jpg"
import img_jessica_barraza from "../../assets/equipo/jessica_barraza.jpg"
import img_marjorie_sierra from "../../assets/equipo/marjorie_sierra.jpg"
import img_yerko_ocaranza from "../../assets/equipo/yerko_ocaranza.jpg"
import img_carolina_gonzalez from "../../assets/equipo/carolina_gonzalez.jpg"
import img_gustavo_arriaran from "../../assets/equipo/gustavo_arriaran.jpg"
import img_monica_nancupan from "../../assets/equipo/monica_nancupan.jpg"
import img_valentina_lobos from "../../assets/equipo/valentina_lobos.jpg"
import img_maria_jose_crumilla from "../../assets/equipo/maria_jose_crumilla.jpg"
import img_paula_castillo from "../../assets/equipo/paula_castillo.jpg"

export function MiembroEquipoCard({imagen, nombre, cargo}) {
    return (
        <div className={"card-emprendedora-wrapper"}>
            <Imagen className={"card-emprendedora"} src={imagen} width={"100%"} height={"100%"}
                    objectFit={"cover"} style={{border: "1px solid black"}}/>
            <h5>{nombre}</h5>
            <p>{cargo}</p>
        </div>
    )
}

const equipo_mujerezas = [
    {
        imagen: img_raul_castillo,
        nombre: "Raúl Castillo Villagra",
        cargo: "Director de Proyecto"
    },
    {
        imagen: img_sandra_gonzalez,
        nombre: "Sandra González Navarrete",
        cargo: "Coordinadora General"
    },
    {
        imagen: img_sheyla_etcheverry,
        nombre: "Sheyla Etcheverry Muñoz",
        cargo: "Profesional Área de Emprendimiento e Innovación"
    },
    {
        imagen: img_jessica_barraza,
        nombre: "Jessica Barraza Rivera",
        cargo: "Profesional Experta en Currículum"
    },
    {
        imagen: img_marjorie_sierra,
        nombre: "Marjorie Sierra Tucas",
        cargo: "Profesional de Apoyo Curricular"
    },
    {
        imagen: img_yerko_ocaranza,
        nombre: "Yerko Ocaranza Sepúlveda",
        cargo: "Profesional de Apoyo a la Innovación"
    },
    {
        imagen: img_gustavo_arriaran,
        nombre: "Gustavo Arriarán",
        cargo: "Profesional de Apoyo a la Investigación"
    },
    {
        imagen: img_carolina_gonzalez,
        nombre: "Carolina González Velásquez",
        cargo: "Profesional de Apoyo Técnico"
    },
    {
        imagen: img_monica_nancupan,
        nombre: "Mónica Ñancupán González",
        cargo: "Profesional de Vinculación"
    },
    {
        imagen: img_valentina_lobos,
        nombre: "Valentina Lobos Santiago",
        cargo: "Diseñadora y Community Manager"
    },
    {
        imagen: img_maria_jose_crumilla,
        nombre: "María José Crumilla",
        cargo: "Encargada Financiera"
    },
    {
        imagen: img_paula_castillo,
        nombre: "Paula Andrea Castillo Rojo",
        cargo: "Secretaria"
    }
]

export default function EquipoCards() {
    const [equipo, setEquipo] = useState([]);

    useEffect(() => {
        setEquipo(equipo_mujerezas)
    }, [equipo])


    return (
        <div className="equipo-cards-wrapper">
            {equipo.map((integrante, index) => {
                return <MiembroEquipoCard key={index} imagen={integrante.imagen}
                                          nombre={integrante.nombre}
                                          cargo={integrante.cargo}/>

            })}
        </div>
    )
}
