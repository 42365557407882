import React from "react";
import './capsula-card.css'

export default function CapsulaCard({capsula}) {
    return (
        <div className={"capsula-card"}>
            <div className={"capsula-card-video"}>
                <video width={"100%"} height={"100%"} controls preload="metadata">
                    <source src={`${capsula.archivo}#t=0.001`} type="video/mp4"/>
                    Su navegador no permite la visualización de videos.
                </video>
            </div>
            {(capsula.titulo || capsula.descripcion) ?
                <div className={"capsula-card-texto"}>
                    {capsula.titulo ? <h1>{capsula.titulo}</h1> : null}
                    {capsula.descripcion ? <p>{capsula.descripcion}</p> : null}
                </div> : null
            }
        </div>
    )
}
