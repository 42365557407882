import React, {useEffect, useState} from "react";
import './menu-primario.css';
import {NavLink, useLocation} from "react-router-dom";
import menu_icon from './../../../assets/menu_primario/menu_icon.svg'
import {Imagen} from "../../imagenes/Imagenes";

const secciones = [
    {
        titulo: "Inicio",
        path: "",
        to: ""
    },
    {
        titulo: "Equipo",
        to: "equipo"
    },
    {
        titulo: "Emprendedoras",
        to: "emprendedoras"
    },
    {
        titulo: "Noticias",
        to: "noticias"
    }, {
        titulo: "Cápsulas",
        to: "capsulas"
    },
    {
        titulo: "Próximamente",
        to: "proximamente"
    }
]

export function MenuPrimarioMobiles() {
    const [open, setOpen] = useState(false)
    const pathname = useLocation().pathname;

    function handleClick(){
        setOpen(!open)
    }

    return (
        <>
            <div onClick={handleClick}>
                <Imagen className={"menu-primario-icon"} height={"100%"} src={menu_icon}
                        alt="menu-primario-icon"></Imagen>
            </div>
            {open ?
                <nav className={"menu-primario"}>
                    <ul className={"menu-ul"}>
                        {secciones.map((seccion, index) => {
                            return (
                                <li key={index} className="menu-li">
                                    <NavLink className={`menu-navlink ${pathname === seccion.path ? 'active' : ''}`}
                                             to={`/${seccion.to}`}
                                             reloadDocument>
                                        {seccion.titulo}
                                    </NavLink>
                                </li>
                            )
                        })}
                    </ul>
                </nav> : null
            }
        </>
    );
}

function MenuPrimarioDesktop() {
    const pathname = useLocation().pathname;

    return (
        <nav className={"menu-primario"}>
            <ul className={"menu-ul"}>
                {secciones.map((seccion, index) => {
                    return (
                        <li key={index} className="menu-li">
                            <NavLink className={`menu-navlink ${pathname === seccion.path ? 'active' : ''}`}
                                     to={`/${seccion.to}`}
                                     reloadDocument>
                                {seccion.titulo}
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
        </nav>
    );
}


export default function MenuPrimario() {
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 768;

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <>
            {
                width >= breakpoint ?
                    <MenuPrimarioDesktop/> : <MenuPrimarioMobiles/>
            }
        </>
    )

}
