import React, {useState} from 'react';
import './login-form.css';
import {validarPassword} from "../validadores";
import {getRequest, postTokenRequest} from "../../../requests/requests";
import {useNavigate} from "react-router-dom";

export default function FormularioCambiarContrasena({token}) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        password: '',
        passwordConfirmation: '',
        token: token
    })

    const [formErrors, setFormErrors] = useState({password: ''});
    const [waitingForResponse, setWaitingForResponse] = useState(false);
    const [formStatus, setFormStatus] = useState({estado: 'waiting', message: ''});

    const handleSubmit = async event => {
        setWaitingForResponse(true)
        event.preventDefault();

        let esValido = true
        let errors = {
            password: '',
        }

        const validacionPassword = validarPassword(formData.password, formData.passwordConfirmation)
        if (!validacionPassword.valido) {
            errors.password = validacionPassword.mensaje;
            esValido = false
        }

        setFormErrors(errors)
        if (esValido) {
            let data = new FormData();
            data.append('password', formData.password);
            data.append('passwordConfirmation', formData.passwordConfirmation);
            data.append('token', formData.token);


            await getRequest("authentication/crsf/")
                .then(async () =>
                    await postTokenRequest('authentication/cambiar_contrasena/', data)
                        .then((response) => {
                            if (response.status === 200) {
                                setFormStatus({estado: 'success', message: ''})
                            }
                        })
                        .catch((error) => {
                            if (error.status === 400) {
                                setFormStatus({estado: 'fail', message: error.response.data.message})
                            } else {
                                setFormStatus({estado: 'fail', message: ''})
                            }
                        }))
                .catch(() => setFormStatus({estado: 'fall', message: ''})
                )
        }
        setWaitingForResponse(false)


    }
    const handleChange = event => {
        let name = event.target.name
        let value = event.target.value
        setFormData((values => ({...values, [name]: value})))
    }

    if (formStatus.estado === 'success') {
        setTimeout(() => navigate('/login'), 5000)
        return <>
            <h1>Su contraseña ha sido cambiada con éxito</h1>
            <p>En un momento será redirigido a la página de iniciar sesión.</p>
        </>
    }
    if (formStatus.estado === 'fail') {
        return <>
            <h1>Tuvimos un problema al cambiar la contraseña</h1>
            <p>{formStatus.message ? formStatus.message : "Por favor inténtelo de nuevo más tarde"}</p>
        </>
    } else {
        return (
            <>
                <h1>Cambiar Contraseña</h1>
                <p>Para cambiar su contraseña ingrese los datos requeridos.</p>
                <form className={"login-form"} onSubmit={handleSubmit}>
                    <label>
                        <strong>Contraseña</strong>
                        {formErrors.password && <div className="error">{formErrors.password}</div>}
                        <input type="password" name="password" value={formData.password} autoComplete={"true"}
                               onChange={handleChange}/>
                    </label>
                    <label>
                        <strong>Repita su contraseña</strong>
                        <input type="password" name="passwordConfirmation" value={formData.password2}
                               autoComplete={"true"}
                               onChange={handleChange}/>
                    </label>
                    <button type="submit" disabled={waitingForResponse}>Activar</button>
                </form>
            </>
        )
    }

}
