import {Modal} from "@mui/material";
import ImageField from "../forms/fields/ImageField";
import {Boton} from "../boton/Botones";
import React, {useState} from "react";
import './modal-emprendedora.css'

export default function ModalEmprendedora({open, onCloseModal, onConfirmModal}) {
    const [imagen, setImagen] = useState(null);
    
    const handleConfirm = (event) => {
        event.preventDefault();
        if (imagen) {
            onConfirmModal(imagen);
        }
        handleClose()
    }

    const handleClose = () => {
        setImagen(null)
        onCloseModal()
    }

    const handleChange = (event) => {
        event.preventDefault()
        let file = event.target.files.length > 0 ? event.target.files[0] : null
        setImagen(file);
    }

    return (
        <Modal
            open={open}
        >
            <div className={"modal-emprendedora"}>
                <div>
                    <h2 className={"flex justify-center"}>Ingrese la Fotografía del Producto</h2>
                    <div className={"flex justify-center"}>
                        <ImageField name="fotografia" src={imagen} onChange={handleChange}
                                    width={"300px"} aspectRatio="16/9"/>
                    </div>
                    <div className={"modal-emprendedora-botones"}>
                        <Boton color={"boton-azul"} texto={"Añadir"} onClick={handleConfirm}/>
                        <Boton color={"boton-rojo"} texto={"Cancelar"} onClick={handleClose}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
