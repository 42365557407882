import React from 'react';
import './pagina-crear-noticia.css'
import FormularioCrearNoticia from "../../../components/forms/noticias/FormularioCrearNoticia";

export default function PaginaEditarNoticia() {


    return (
        <div className={"pagina-crear-noticia-wrapper"}>
            <div className={"formulario-wrapper"}>
                <FormularioCrearNoticia accion={"editar"}></FormularioCrearNoticia>
            </div>
        </div>
    )

}
