import React from "react";
import {IconButton} from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const DestacarIcon = ({destacado, onClick}) => {
    const color = destacado ?  '#eda73e' : null

    return (
        <IconButton onClick={onClick}>
            <StarIcon style={{color: color}}/>
        </IconButton>
    )
}

export const EditarIcon = ({onClick}) => {
    return (
        <IconButton onClick={onClick}>
            <EditIcon/>
        </IconButton>
    )
}

export const EliminarIcon = ({onClick}) => {
    return(
        <IconButton onClick={onClick}>
            <DeleteIcon/>
        </IconButton>
    )
}
