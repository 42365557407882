import React, {createContext, useCallback, useState} from "react";
import {getRequest, getTokenRequest, postTokenRequest} from "../requests/requests";
import {useNavigate} from "react-router-dom";

export const AuthContext = createContext(undefined);

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loginError, setLoginError] = useState('');


    const getUserInfo = useCallback(() => {
        getTokenRequest('admin/usuarios/info/').then((response) => {
            setUser(response.data);
            setIsAuthenticated(true)
        }).catch(() => {
        })
    }, [])


    const login = async (credenciales) => {
        setLoginError('')
        let data = new FormData();
        data.append('rut', credenciales.rut);
        data.append('password', credenciales.password);

        getRequest("authentication/crsf/")
            .then(async () =>
                await postTokenRequest('authentication/login/', data)
                    .then((response) => {
                        if (response.status === 200) {
                            navigate('/admin')
                        }
                    })
                    .catch((error) => {
                        setLoginError(error.response.data)
                    }))
            .catch((error) => console.log(error))
    }

    const logout = async () => {
        await postTokenRequest('authentication/logout/')
            .then((response) => {
                if (response.status === 200) {
                    setIsAuthenticated(false)
                    setUser({})
                    navigate('/login')
                }
            })
            .catch(() => {
            })
    }


    return (
        <AuthContext.Provider value={{user, isAuthenticated, getUserInfo, login, logout, loginError}}>
            {children}
        </AuthContext.Provider>
    )
}

