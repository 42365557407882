import {Imagen} from "../../imagenes/Imagenes";
import React, {useState} from "react";
import './../form-styles.css'
import ImagenVacia from "../../../assets/mujerezas/imagen_vacia.png";

export default function ImageField({src, name, onChange, aspectRatio, width}) {
    const [imagen, setImagen] = useState(src ? src : ImagenVacia);

    const handleChange = (event) => {
        event.preventDefault();
        if (event.target.files.length > 0) {
            setImagen(URL.createObjectURL(event.target.files[0]));
        }
        else{
            setImagen(ImagenVacia)
        }
        onChange(event);
    }

    return (
        <div className={"image-field"}>
            <Imagen src={imagen} width={"100%"} height={"100%"}
                    style={{width: width ? width : "300px", aspectRatio: aspectRatio}}
                    aspectRatio={aspectRatio}

            />
            <input
                name={name ? name: "imagen"}
                type="file"
                accept={".jpg, .png, .jpeg"}
                value={undefined}
                onChange={handleChange}
            />
        </div>
    )
}


