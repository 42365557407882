import React from 'react'
import './header.css'
import logo from '../../assets/mujerezas/logo.mujerezas.svg'
import MenuPrimario from "../../components/menus/menu_primario/MenuPrimario";
import {LinkImagen} from "../../components/imagenes/Imagenes";

export default function Header() {
    return (
        <header>
            <div className={"content-wrapper header-wrapper"}>
                <LinkImagen className={'header-image-wrapper'} width={"100%"} height={"100%"} to={""} src={logo} alt={'logo_mujerezas'}/>
                <MenuPrimario/>
            </div>
        </header>
    )
}
