import React, {useEffect, useState} from "react";
import {Pagination} from "@mui/material";
import './paginacion.css'


export default function Paginacion({numberOfPages, page, onChange}) {

    const [size, setSize] = useState('');

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    const handleWindowResize = () => {
        const width = window.innerWidth;
        if (width < 600) {
            setSize('small')
        } else if (width >= 600 && width < 900) {
            setSize('medium')
        } else {
            setSize('large')
        }


    }

    return <div className={"paginacion"}>
        <Pagination count={numberOfPages} page={page} siblingCount={1} boundaryCount={1} size={size}
                    onChange={onChange}/>
    </div>

}
