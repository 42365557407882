import React from 'react';
import LogoMujerezas from "../../assets/mujerezas/logo.mujerezas.svg";
import './login-styles.css'
import LoginForm from "../../components/forms/login/LoginForm";
import {useNavigate} from "react-router-dom";
import {Imagen} from "../../components/imagenes/Imagenes";

export default function PaginaLogin() {
    const navigate = useNavigate();

    return (
        <div className={"login-page-wrapper"}>
            <div className={"login-page-content"}>
                <Imagen src={LogoMujerezas} className={"login-logo"}/>
                <h1>Ingresar</h1>
                <div className={"login-form-wrapper"}>
                    <LoginForm/>
                </div>
                <button className={'login-page-olvido-contrasena'}
                        onClick={() => navigate('/recuperar_contrasena')}>¿Olvidó su contraseña?
                </button>
            </div>
        </div>
    )
}
