import React, {useContext, useEffect, useState} from "react";
import './../form-styles.css'
import './formulario-crear-noticia.css'
import TextEditorField from "../fields/TextEditorField";
import {getTokenRequest, postTokenRequest, patchTokenRequest} from "../../../requests/requests";
import {FeedbackContext} from "../../../context/FeedbackContext";
import {useNavigate, useParams} from "react-router-dom";
import ImageField from "../fields/ImageField";
import Feedbacks from "../../feedback/Feedback";
import {validarExistencia, validarURL} from "../validadores";
import {Boton} from "../../boton/Botones";

export default function FormularioCrearNoticia({accion}) {
    const navigate = useNavigate()
    const id = useParams().id;
    const {feedbackList, pushFeedback} = useContext(FeedbackContext);
    const [loaded, setLoaded] = useState(false);
    const [waitingForResponse, setWaitingForResponse] = useState(false);

    const [noticia, setNoticia] = useState({
        titulo: '',
        bajada: '',
        cuerpo: '',
        imagen: '',
    })

    const [formErrors, setFormErrors] = useState({
        titulo: '',
        bajada: '',
        cuerpo: '',
        imagen: '',
    })


    useEffect(() => {
            if (accion === 'editar') {
                getTokenRequest(`admin/noticias/detalle/${id}/`).then((response) => {
                    setNoticia(response.data);
                    setLoaded(true);
                }).catch(error => console.log(error))
            } else {
                setLoaded(true)
            }
        }, [accion, id]
    )


    const changeNoticia = (name, value) => {
        setNoticia((prevFormData) => ({...prevFormData, [name]: value}));
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        changeNoticia(name, value);
    };

    const handleTextEditorChange = (formElementName, value) => {
        setNoticia((prevFormData) => ({...prevFormData, [formElementName]: value}))
    };

    const handleFileChange = (event) => {
        event.preventDefault()
        let file = event.target.files.length > 0 ? event.target.files[0] : ''
        changeNoticia([event.target.name], file)
    }

    const validarFormulario = () => {
        let valido = true

        let errors = {
            titulo: '',
            bajada: '',
            cuerpo: '',
            imagen: '',
        }


        let validacionTitulo = validarExistencia(noticia.titulo);
        if (!validacionTitulo.valido) {
            errors.titulo = validacionTitulo.mensaje;
            valido = false;
        }

        if (!(noticia.imagen instanceof File) && !(validarURL(noticia.imagen).valido)) {
            errors.imagen = "Debe subir una imagen";
            valido = false
        }


        if (!valido) {
            window.scroll(0, 0)
            setFormErrors(errors)
        }
        return valido
    }

    const handleSubmit = async (event) => {
        setWaitingForResponse(true)
        event.preventDefault();

        let valido = validarFormulario()


        if (valido && !waitingForResponse) {
            let data = new FormData();
            data.append('titulo', noticia.titulo);
            data.append('bajada', noticia.bajada);
            data.append('cuerpo', noticia.cuerpo);
            if (noticia.imagen instanceof File)
                data.append('imagen', noticia.imagen);

            if (accion === 'editar') {
                await patchTokenRequest(`admin/noticias/editar/${id}/`, data).then((response) => {
                        if (response.status === 200) {
                            pushFeedback("Noticia editada exitosamente")
                            navigate('/admin/noticias/todas');
                        }
                    }
                ).catch(() => {
                    pushFeedback('Tuvimos un problema al editar la noticia', "error")
                    window.scroll(0, 0)
                });

            } else {
                await postTokenRequest('/admin/noticias/crear/', data).then((response) => {
                        if (response.status === 201) {
                            pushFeedback("Noticia creada exitosamente")
                            navigate('/admin/noticias/todas');
                        }
                    }
                ).catch(() => {
                    pushFeedback('Tuvimos un problema al crear la noticia', "error")
                    window.scroll(0, 0)
                });
            }
        }
        setWaitingForResponse(false)
    }

    const handlePreview = () => {
        if (validarFormulario()) {
            let previewNoticia = {
                titulo: noticia.titulo,
                bajada: noticia.bajada,
                cuerpo: noticia.cuerpo,
                imagen: (noticia.imagen instanceof File) ? URL.createObjectURL(noticia.imagen) : noticia.imagen,
            }

            localStorage.setItem('previewNoticia', JSON.stringify(previewNoticia));
            window.open('/noticias/previsualizar', 'preview')
        }
    }

    if (loaded) {
        return <>
            <Feedbacks feedbackList={feedbackList}/>
            {accion === 'editar' ? <h1>Editar noticia</h1> : <h1>Crear una noticia</h1>}
            {accion !== 'editar' ? <p>Para crear una noticia rellene los campos requeridos.</p> : null}
            <form className={"formulario"} onSubmit={handleSubmit}>
                <div className={'form-field'}>
                    <label htmlFor="fname"><span>Título:</span> <span className={"rojo"}>*</span></label>
                    <div className={"rojo"}>{formErrors.titulo}</div>
                    <input type="text" id="titulo" name="titulo" value={noticia.titulo} onChange={handleChange}/>
                </div>
                <div className={'form-field bajada'}>
                    <label htmlFor="fname">Bajada:</label>
                    <div className={"text-editor-wrapper"}>
                        <TextEditorField value={noticia.bajada}
                                         onChange={(value) => handleTextEditorChange('bajada', value)}
                                         tipo="texto"
                        />
                    </div>
                </div>
                <div className={'form-field cuerpo'}>
                    <label htmlFor="fname">Cuerpo:</label>
                    <TextEditorField value={noticia.cuerpo}
                                     onChange={(value) => handleTextEditorChange('cuerpo', value)}
                                     tipo="multimedia"
                    />
                </div>
                <div className={'form-field'}>
                    <label htmlFor={"fname"}>Imagen: <span className={"rojo"}>*</span></label>
                    <div className={"rojo"}>{formErrors.imagen}</div>
                    <ImageField name="imagen" src={noticia.imagen} onChange={handleFileChange} aspectRatio="16/9"/>
                </div>
            </form>
            <div className={"formulario form-actions"}>
                <Boton color={"boton-verde"} texto={"Enviar"} onClick={handleSubmit}/>
                <Boton color={"boton-amarillo"} texto={"Previsualizar"} onClick={handlePreview}/>
            </div>
        </>
    }
    return null
}
