import React, {createContext, useState} from "react";

export const FeedbackContext = createContext(undefined);

export const FeedbackProvider = ({children}) => {
    const [feedbackList, setFeedbackList] = useState([]);

    const pushFeedback = (feedbackMessage, tipo='success') => {
        feedbackList.push(
            {
                id: Math.random().toString().slice(2),
                tipo: tipo,
                contenido: feedbackMessage
            }
        )
    }

    const removeFeedback = (feedback) => {
        setFeedbackList((list) => list.filter((item) => item.id !== feedback.id))
    }

    return (
        <FeedbackContext.Provider value={{feedbackList, pushFeedback, removeFeedback}}>
            {children}
        </FeedbackContext.Provider>
    )
}

