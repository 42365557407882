import React from 'react';
import FormularioCapsula from "../../../components/forms/capsulas/FormularioCapsula";

export default function PaginaEditarCapsula() {


    return (
        <div className={"pagina-editar-capsula-wrapper"}>
            <div className={"formulario-wrapper"}>
                <FormularioCapsula accion={"editar"}></FormularioCapsula>
            </div>
        </div>
    )

}
