import React from 'react';
import FormularioCarruselInicio from "../../../components/forms/Inicio/FormularioCarruselInicio";

export default function PaginaAnadirInicioCarruselImagen() {

    return (
        <div className={"pagina-admin-inicio-carrusel-anadir-wrapper"}>
            <div className={"formulario-wrapper"}>
                <FormularioCarruselInicio/>
            </div>
        </div>
    )

}
