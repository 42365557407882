import './text-editor-field.css'
import {forwardRef, useEffect, useLayoutEffect, useRef} from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import * as Emoji from "quill2-emoji";
import 'quill2-emoji/dist/style.css';

require('default-passive-events');

const VideoEmbed = Quill.import("formats/video")

class VideoResponsive extends VideoEmbed {
    static aspectRatio = "16 / 9 auto"

    static create(value) {
        const node = super.create(value);
        node.style.aspectRatio = this.aspectRatio;
        return node;
    }

    html() {
        return this.domNode.outerHTML;
    }
}


Quill.register("modules/emoji", Emoji, true);
Quill.register(VideoResponsive, true);

const Editor = forwardRef(
    ({readOnly, defaultValue, onTextChange, onSelectionChange, onChange, tipo}, ref) => {
        const containerRef = useRef(null);
        const onTextChangeRef = useRef(onTextChange);
        const onSelectionChangeRef = useRef(onSelectionChange);

        const tipoRef = useRef(tipo);

        let toolBar = [
            [{header: [1, 2, 3, false]}],
            ['bold', 'italic', 'underline', 'emoji'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['link', 'image', 'video'],
            ['clean']]

        if (tipoRef.current === 'texto') {
            toolBar = [
                ['bold', 'italic', 'underline', 'emoji'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                ['clean']
            ]
        }

        useLayoutEffect(() => {
            onTextChangeRef.current = onTextChange;
            onSelectionChangeRef.current = onSelectionChange;
        });

        useEffect(() => {
            ref.current?.enable(!readOnly);
        }, [ref, readOnly]);

        useEffect(() => {
            const container = containerRef.current;
            const editorContainer = container.appendChild(
                container.ownerDocument.createElement('div'),
            );
            const quill = new Quill(editorContainer, {
                modules: {
                    toolbar: toolBar,
                    'emoji-toolbar': true
                },

                theme: 'snow',
            });

            ref.current = quill;

            quill.clipboard.dangerouslyPasteHTML(defaultValue)

            quill.on(Quill.events.TEXT_CHANGE, (...args) => {
                onTextChangeRef.current?.(...args);
                onChange(quill.getSemanticHTML())
            });

            quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
                onSelectionChangeRef.current?.(...args);
            });

            return () => {
                ref.current = null;
                container.innerHTML = '';
            };
        }, [ref]);

        return <div ref={containerRef}></div>;
    },
);

Editor.displayName = 'Editor';


export default function TextEditorField({readOnly, value, onChange, tipo}) {
    const quillRef = useRef();

    return (
        <Editor
            ref={quillRef}
            readOnly={readOnly}
            defaultValue={value}
            onChange={onChange}
            tipo={tipo}
        />
    )
}
