import React, {useEffect, useState} from 'react';
import './pagina-noticias.css'
import NoticiaCard from "../../../components/cards/NoticiaCard";
import {getRequest} from "../../../requests/requests";
import Paginacion from "../../../components/paginacion/Paginacion";
import {useNavigate, useParams} from "react-router-dom";

export default function PaginaNoticias() {
    const navigate = useNavigate()
    const [noticias, setNoticias] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const pagina = parseInt(useParams().pagina)
    const [page, setPage] = useState(pagina ? pagina : 1);

    useEffect(() => {
        getRequest(`noticias/todas/?pagina=${page}`).then((response) => {
                setNoticias(response.data.results)
                setNumberOfPages(Math.ceil(response.data.count / 5))
            }
        ).catch((error) => {
            console.log(error);
        })
    }, [page, setNoticias, setNumberOfPages]);

    const handlePageChange = (event, pageNumber) => {
        setPage(pageNumber)
        setNoticias([])
        navigate(`/noticias/pagina/${pageNumber}`);
        window.scrollTo(0, 0);
    }

    return (
        <div className={"pagina-wrapper pagina-noticias"}>
            <h1>Noticias</h1>
            {noticias.length ?
                <>
                    {noticias.map((noticia, index) => (
                        <NoticiaCard key={index} noticia={noticia}/>

                    ))}
                    <div className={"pagina-noticias-paginacion-wrapper"}>
                        <Paginacion numberOfPages={numberOfPages} page={page} onChange={handlePageChange}/>
                    </div>
                </>
                : null}
        </div>
    )
}
