import React from 'react';
import FormularioUsuario from "../../../components/forms/usuarios/FormularioUsuario";

export default function PaginaEditarUsuario() {

    return (
        <div className={"pagina-crear-usuario-wrapper"}>
            <div className={"formulario-wrapper"}>
                <FormularioUsuario accion={'editar'}/>
            </div>
        </div>
    )

}
