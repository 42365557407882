import './App.css';
import {Route, Routes} from 'react-router-dom';
import PaginaInicio from "./paginas/inicio/public/PaginaInicio";
import PaginaEquipo from "./paginas/equipo/PaginaEquipo";
import PaginaEmprendedoras from "./paginas/emprendedoras/public/PaginaEmprendedoras";
import PaginaNoticias from "./paginas/noticias/public/PaginaNoticias";
import PaginaCapsulas from "./paginas/capsulas/public/PaginaCapsulas";
import PaginaProximamente from "./paginas/proximamente/PaginaProximamente";
import PaginaNoticiaDetalle from "./paginas/noticias/public/PaginaNoticiaDetalle";
import PaginaLogin from "./paginas/login/PaginaLogin";
import SitioPrincipalLayout from "./components/layout/sitio-principal/SitioPrincipalLayout";
import SitioAdminLayout from "./components/layout/sitio-admin/SitioAdminLayout";
import PaginaCrearNoticia from "./paginas/noticias/admin/PaginaCrearNoticia";
import PaginaAdminNoticias from "./paginas/noticias/admin/PaginaAdminNoticias";
import PaginaEditarNoticia from "./paginas/noticias/admin/PaginaEditarNoticia";
import PaginaAdminCapsulas from "./paginas/capsulas/admin/PaginaAdminCapsulas";
import PaginaCrearCapsula from "./paginas/capsulas/admin/PaginaCrearCapsula";
import PaginaEditarCapsula from "./paginas/capsulas/admin/PaginaEditarCapsula";
import PaginaAdminInicioCarrusel from "./paginas/inicio/admin/PaginaAdminInicioCarrusel";
import PaginaAnadirInicioCarruselImagen from "./paginas/inicio/admin/PaginaAnadirInicioCarruselImagen";
import PaginaEditarInicioCarruselImagen from "./paginas/inicio/admin/PaginaEditarInicioCarruselImagen";
import {AuthProvider} from "./context/AuthContext";
import PaginaAdminUsuarios from "./paginas/usuarios/admin/PaginaAdminUsuarios";
import PaginaAnadirUsuario from "./paginas/usuarios/admin/PaginaAnadirUsuario";
import PaginaEditarUsuario from "./paginas/usuarios/admin/PaginaEditarUsuario";
import PaginaCambiarContrasena from "./paginas/login/PaginaCambiarContrasena";
import PaginaAdminCambiarContrasena from "./paginas/usuarios/admin/PaginaAdminCambiarContrasena";
import PaginaAdminInicio from "./paginas/inicio/admin/PaginaAdminInicio";
import PaginaRecuperarContrasena from "./paginas/login/PaginaRecuperarContrasena";
import PaginaAdminEmprendedora from "./paginas/emprendedoras/admin/PaginaAdminEmprendedora";
import PaginaCrearEmprendedora from "./paginas/emprendedoras/admin/PaginaCrearEmprendedora";
import PaginaEditarEmprendedora from "./paginas/emprendedoras/admin/PaginaEditarEmprendedora";


function App() {
    return (
        <AuthProvider>
            <Routes>
                <Route path="" element={<SitioPrincipalLayout/>}>
                    <Route path="" element={<PaginaInicio/>}/>
                    <Route path="equipo" element={<PaginaEquipo/>}/>
                    <Route path="emprendedoras" element={<PaginaEmprendedoras/>}/>
                    <Route path="noticias" element={<PaginaNoticias/>}/>
                    <Route path="noticias/pagina/:pagina" element={<PaginaNoticias/>}/>
                    <Route path="noticias/:id" element={<PaginaNoticiaDetalle/>}/>
                    <Route path="noticias/previsualizar" element={<PaginaNoticiaDetalle accion="previsualizar"/>}/>
                    <Route path="capsulas" element={<PaginaCapsulas/>}/>
                    <Route path="capsulas/pagina/:pagina" element={<PaginaCapsulas/>}/>
                    <Route path="proximamente" element={<PaginaProximamente/>}/>
                </Route>
                <Route path="login" element={<PaginaLogin/>}/>
                <Route path="recuperar_contrasena" element={<PaginaRecuperarContrasena/>}/>
                <Route path="cambiar_contrasena/:token" element={<PaginaCambiarContrasena/>}/>
                <Route path="admin" element={<SitioAdminLayout/>}>
                    <Route path="" element={<PaginaAdminInicio/>}></Route>
                    <Route path="cambiar_contrasena" element={<PaginaAdminCambiarContrasena/>}/>
                    <Route path="inicio/carrusel" element={<PaginaAdminInicioCarrusel/>}/>
                    <Route path="inicio/carrusel/anadir" element={<PaginaAnadirInicioCarruselImagen/>}/>
                    <Route path="inicio/carrusel/editar/:id" element={<PaginaEditarInicioCarruselImagen/>}/>
                    <Route path="noticias/todas" element={<PaginaAdminNoticias/>}/>
                    <Route path="noticias/crear" element={<PaginaCrearNoticia/>}/>
                    <Route path="noticias/editar/:id" element={<PaginaEditarNoticia/>}/>
                    <Route path="capsulas/todas" element={<PaginaAdminCapsulas/>}/>
                    <Route path="capsulas/crear" element={<PaginaCrearCapsula/>}/>
                    <Route path="capsulas/editar/:id" element={<PaginaEditarCapsula/>}/>
                    <Route path="emprendedoras/todas" element={<PaginaAdminEmprendedora/>}/>
                    <Route path="emprendedoras/crear" element={<PaginaCrearEmprendedora/>}/>
                    <Route path="emprendedoras/editar/:id" element={<PaginaEditarEmprendedora/>}/>
                    <Route path="usuarios/todos" element={<PaginaAdminUsuarios/>}/>
                    <Route path="usuarios/anadir" element={<PaginaAnadirUsuario/>}/>
                    <Route path="usuarios/editar/:id" element={<PaginaEditarUsuario/>}/>
                </Route>
            </Routes>
        </AuthProvider>
    );
}

export default App;

