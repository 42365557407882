import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../../context/FeedbackContext";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {deleteTokenRequest, getTokenRequest, patchTokenRequest} from "../../../requests/requests";
import {Imagen} from "../../../components/imagenes/Imagenes";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import Feedbacks from "../../../components/feedback/Feedback";
import {DestacarIcon, EditarIcon, EliminarIcon} from "../../../components/imagenes/Iconos";
import Paginacion from "../../../components/paginacion/Paginacion";
import './pagina-admin-noticias.css'
import {Boton} from "../../../components/boton/Botones";


export default function PaginaAdminNoticias() {
    const navigate = useNavigate();
    const {feedbackList, pushFeedback} = useContext(FeedbackContext);
    const [noticias, setNoticias] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getTokenRequest(`admin/noticias/todas/?pagina=${page}`).then((response) => {
                setNoticias(response.data.results)
                setNumberOfPages(Math.ceil(response.data.count / 5))
            }
        ).catch((error) => {
            console.log(error);
        })
    }, [page, setNoticias]);


    const handleCrearNoticiaClick = () => {
        navigate('/admin/noticias/crear');
    }

    const handleEditarNoticiaClick = (idNoticia) => {
        navigate(`/admin/noticias/editar/${idNoticia}`);
    }

    const deleteNoticia = (idNoticia) => {
        deleteTokenRequest(`admin/noticias/eliminar/${idNoticia}/`).then((response) => {
            if (response.status === 204) {
                setNoticias(noticias => noticias.filter(noticia => noticia.id !== idNoticia));
                pushFeedback("La noticia ha sido eliminada exitosamente")
            }
        }).catch(error => {
            console.log(error)
        });
    }

    const handleEliminarNoticiaClick = (idNoticia) => {
        Swal.fire({
            title: "¿Está seguro que desea continuar?",
            text: `Al eliminar esta noticia no podrá recuperarla`,
            icon: "warning",
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then((result) => {
                if (result.isConfirmed) {
                    deleteNoticia(idNoticia)
                }
            }
        )
    }

    const handleDestacarNoticiaClick = (idNoticia, destacadaStatus) => {
        let data = new FormData();
        data.append('destacada', destacadaStatus);

        patchTokenRequest(`admin/noticias/destacar/${idNoticia}/`, data).then((response) => {
            if (response.status === 200) {
                let newNoticias = noticias.map(noticia => {
                    if (noticia.id === idNoticia) {
                        noticia.destacada = destacadaStatus
                    }
                    return noticia
                })
                setNoticias(newNoticias);
            }
        }).catch(error => {
            console.log(error)
        });
    }

    const handlePageChange = (event, page) => {
        setPage(page);
        window.scroll(0, 0)
    }


    return (
        <div className={"pagina-admin-noticias-wrapper"}>
            <Feedbacks feedbackList={feedbackList}/>
            <div className={"flex space-between align-center"}>
                <h1>Todas las Noticias</h1>
                <Boton color={"boton-azul"} texto={"Crear Noticia"} onClick={handleCrearNoticiaClick}/>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Imagen</TableCell>
                            <TableCell>Titulo</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {noticias.map((noticia, index) => (
                            <TableRow
                                key={index}
                            >
                                <TableCell>
                                    <Imagen src={noticia.imagen} width={"100%"} aspectRatio={"16/9"}
                                            style={{width: "100px"}}
                                    />
                                </TableCell>
                                <TableCell>{noticia.titulo}</TableCell>
                                <TableCell>
                                    <div className={"flex"}>
                                        <DestacarIcon destacado={noticia.destacada}
                                                      onClick={() => handleDestacarNoticiaClick(noticia.id, !noticia.destacada)}/>
                                        <EditarIcon onClick={() => handleEditarNoticiaClick(noticia.id)}/>
                                        <EliminarIcon onClick={() => handleEliminarNoticiaClick(noticia.id)}/>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={"pagina-admin-noticias-paginacion"}>
                <Paginacion numberOfPages={numberOfPages} page={page} onChange={handlePageChange}/>
            </div>
        </div>
    )
}
