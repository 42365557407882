import React, {useState} from "react";
import './../form-styles.css'

export default function VideoField({src, name, onChange}) {

    const [video, setVideo] = useState(src);

    const handleChange = (event) => {
        event.preventDefault();
        if (event.target.files.length > 0) {
            setVideo(URL.createObjectURL(event.target.files[0]));
        } else {
            setVideo('')
        }
        onChange(event);
    }

    return (
        <div className={"flex align-center"}>
            <video src={video} style={{width: "300px", aspectRatio: "16/9", backgroundColor: "black"}} controls>
            </video>
            <input
                name={name ? name : "video"}
                type="file"
                accept="video/*"
                value={undefined}
                onChange={handleChange}
            />
        </div>
    )
}
