import React, {useContext, useEffect, useState} from "react";
import './../form-styles.css'
import {getTokenRequest, postTokenRequest, patchTokenRequest} from "../../../requests/requests";
import {FeedbackContext} from "../../../context/FeedbackContext";
import {useNavigate, useParams} from "react-router-dom";
import Feedbacks from "../../feedback/Feedback";
import {Boton} from "../../boton/Botones";
import {formatearRut, validarEmail, validarRut} from "../validadores";

export default function FormularioUsuario({accion}) {
    const navigate = useNavigate()
    const id = useParams().id;

    const getEditarPrefixURL = 'admin/usuarios/detalle/'
    const postCrearURL = 'admin/usuarios/crear/'
    const patchEditarPrefixURL = 'admin/usuarios/editar/'

    const onSuccessRedirectPATH = '/admin/usuarios/todos'
    const onSuccessCreateFeedbackMessage = "Usuario creado exitosamente"
    const onErrorCreateFeedbackMessage = "Tuvimos un problema al crear el usuario."
    const onSuccessEditFeedbackMessage = "Usuario editado exitosamente"
    const onErrorEditFeedbackMessage = "Tuvimos un problema al editar el usuario."


    const {feedbackList, pushFeedback} = useContext(FeedbackContext);
    const [loaded, setLoaded] = useState(false);
    const [waitingForResponse, setWaitingForResponse] = useState(false);

    const [usuario, setUsuario] = useState({
        rut: '',
        nombre: '',
        apellido: '',
        correo_electronico: '',
        rol: '',
    })
    const [formErrors, setFormErrors] = useState({
        rut: '',
        nombre: '',
        apellido: '',
        correo_electronico: '',
        rol: '',
    })


    useEffect(() => {
            if (accion === 'editar') {
                getTokenRequest(`${getEditarPrefixURL}${id}/`).then((response) => {
                    setUsuario(response.data);
                    setLoaded(true);
                }).catch(error => console.log(error))
            } else {
                setLoaded(true)
            }
        }, [accion, id]
    )

    const handleChange = (event) => {
        let {name, value} = event.target;
        if (name === 'rut') {
            value = formatearRut(value)
        }
        setUsuario((prevFormData) => ({...prevFormData, [name]: value}));
    };

    const handleSubmit = async (event) => {
        setWaitingForResponse(true)
        event.preventDefault();

        let valido = true

        let errors = {
            rut: '',
            nombre: '',
            apellido: '',
            correo_electronico: '',
            rol: '',
        }

        const validacionRut = validarRut(usuario.rut)
        if (!validacionRut.valido) {
            errors.rut = validacionRut.mensaje
            valido = false
        }

        const validacionCorreoElectronico = validarEmail(usuario.correo_electronico.trim())
        if (!validacionCorreoElectronico.valido) {
            errors.correo_electronico = validacionCorreoElectronico.mensaje
            valido = false
        }

        if (!(['Administrador', 'Publicador'].includes(usuario.rol))) {
            errors.rol = "Este campo es requerido"
            valido = false
        }


        setFormErrors(errors)


        if (valido && !waitingForResponse) {
            let data = new FormData();

            data.append('rut', usuario.rut);
            data.append('nombre', usuario.nombre);
            data.append('apellido', usuario.apellido);
            data.append('correo_electronico', usuario.correo_electronico);
            data.append('rol', usuario.rol);

            if (accion === 'editar') {
                await patchTokenRequest(`${patchEditarPrefixURL}${id}/`, data).then((response) => {
                        if (response.status === 200) {
                            pushFeedback(onSuccessEditFeedbackMessage)
                            navigate(onSuccessRedirectPATH);
                        }
                    }
                ).catch(() => {
                    pushFeedback(onErrorEditFeedbackMessage, 'error')
                });
            } else {
                await postTokenRequest(postCrearURL, data).then((response) => {
                        if (response.status === 201) {
                            pushFeedback(onSuccessCreateFeedbackMessage)
                            navigate(onSuccessRedirectPATH);
                        }
                    }
                ).catch(() => {
                        pushFeedback(onErrorCreateFeedbackMessage, 'error')
                    }
                );
            }
        }
        setWaitingForResponse(false)
    }

    if (loaded) {
        return (
            <>
                <Feedbacks feedbackList={feedbackList}/>
                {accion === 'editar' ?
                    <h1>Editar usuario</h1> :
                    <><h1>Añadir un usuario</h1><p>Para añadir un usuario rellene los campos requeridos.</p></>}
                <form className={"formulario"} onSubmit={handleSubmit}>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>Rut </span><span className={"rojo"}>*</span></label>
                        <div className="error">{formErrors.rut}</div>
                        <input type="text" id="rut" name="rut" value={usuario.rut}
                               onChange={handleChange}/>
                    </div>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>Nombre </span><span className={"rojo"}>*</span></label>
                        <input type="text" id="nombre" name="nombre" value={usuario.nombre}
                               onChange={handleChange}/>
                    </div>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>Apellido </span><span className={"rojo"}>*</span></label>
                        <input type="text" id="apellido" name="apellido" value={usuario.apellido}
                               onChange={handleChange}/>
                    </div>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>Correo electrónico </span><span className={"rojo"}>*</span></label>
                        <div className="error">{formErrors.correo_electronico}</div>
                        <input type="text" id="correo_electronico" name="correo_electronico"
                               value={usuario.correo_electronico}
                               onChange={handleChange}/>
                    </div>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>Rol </span><span className={"rojo"}>*</span></label>
                        <div className="error">{formErrors.rol}</div>
                        <select id="rol" name="rol" value={usuario.rol} onChange={handleChange}>
                            <option>-- Seleccione una opción --</option>
                            <option>Publicador</option>
                            <option>Administrador</option>
                        </select>
                    </div>
                    <div className={"formulario form-actions"}>
                    <Boton color={"boton-verde"} texto={"Enviar"} onClick={handleSubmit}/>
                    </div>
                </form>
            </>
        )
    }
    return null
}
