import React, {useContext, useState} from 'react';
import './login-form.css';
import {formatearRut, validarExistencia, validarRut} from "../validadores";
import {AuthContext} from "../../../context/AuthContext";

export default function LoginForm() {

    const [credenciales, setCredenciales] = useState({rut: '', password: ''})
    const [formErrors, setFormErrors] = useState({rut: '', password: ''});
    const {login, loginError} = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async event => {
        setSubmitting(true)
        event.preventDefault();

        let esValido = true
        let errors = {
            rut: '',
            password: '',
        }

        const validacionRut = validarRut(credenciales.rut)
        if (!validacionRut.valido) {
            errors.rut = validacionRut.mensaje
            esValido = false
        }

        const validarPassword = validarExistencia(credenciales.password)
        if (!validarPassword.valido) {
            errors.password = validarPassword.mensaje
            esValido = false
        }

        setFormErrors(errors)

        if (esValido && !submitting) {
            login(credenciales)
        }
        setSubmitting(false)
    }
    const handleChange = event => {
        let name = event.target.name
        let value = event.target.value

        if (name === 'rut') {
            value = formatearRut(value)
        }
        setCredenciales((values => ({...values, [name]: value})))
    }


    return (
        <>
            <form className={"login-form"} onSubmit={handleSubmit}>
                {<div className="error">{loginError}</div>}
                <label>
                    <strong>Rut</strong>
                    <input type="text" name="rut" value={credenciales.rut} onChange={handleChange}/>
                    {formErrors.rut && <div className="error">{formErrors.rut}</div>}
                </label>
                <label>
                    <strong>Contraseña</strong>
                    <input type="password" name="password" value={credenciales.password} onChange={handleChange}/>
                    {formErrors.password && <div className="error">{formErrors.password}</div>}
                </label>
                {formErrors.formulario && <div className="error">{formErrors.formulario}</div>}
                <button type="submit">Ingresar</button>
            </form>
        </>
    )
}
