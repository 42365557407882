import {NavLink} from "react-router-dom";
import React from "react";

export function LinkImagen({className, style, to, src, target, alt, width, height, objectFit}) {
    return (
        <div className={className} style={style}>
            <NavLink to={to} target={target} reloadDocument style={{width: "inherit", height: "inherit"}}>
                <img src={src} alt={alt}
                     style={{width: width, height: height, objectFit: objectFit, verticalAlign: "bottom"}}/>
            </NavLink>
        </div>
    )
}

export function Imagen({className, style, src, alt, width, height, objectFit, aspectRatio}) {
    return (
        <div className={className} style={style}>
            <img src={src} alt={alt} style={{
                width: width,
                height: height,
                objectFit: objectFit,
                verticalAlign: "bottom",
                aspectRatio: aspectRatio
            }}/>
        </div>
    )
}

