import React, {useContext} from 'react';
import {AuthContext} from "../../../context/AuthContext";

export default function PaginaAdminInicio() {
    const {user} = useContext(AuthContext);

    return (
        <div className={"pagina-admin-inicio-wrapper"}>
            <h1>Hola {user.nombre} {user.apellido}</h1>
            <h2>Bienvenido/a al sitio de administración</h2>
        </div>
    )

}
