import React, {useState} from 'react';
import './login-form.css';
import {formatearRut, validarRut} from "../validadores";
import {getRequest, postTokenRequest} from "../../../requests/requests";

export default function FormularioRecuperarContrasena() {

    const [formData, setFormData] = useState({rut: ''})
    const [formErrors, setFormErrors] = useState({general: '', rut: ''});
    const [submitting, setSubmitting] = useState(false);
    const [formStatus, setFormStatus] = useState('pendiente');
    const [email, setEmail] = useState('');


    const handleSubmit = async event => {
        setSubmitting(true)
        event.preventDefault();

        let esValido = true
        let errors = {
            general: '',
            rut: '',
        }

        const validacionRut = validarRut(formData.rut)
        if (!validacionRut.valido) {
            errors.rut = validacionRut.mensaje
            esValido = false
        }


        setFormErrors(errors)

        if (esValido && !submitting) {
            let data = new FormData();
            data.append('rut', formData.rut);

            await getRequest("authentication/crsf/")
                .then(async () =>
                    await postTokenRequest('authentication/recuperar_contrasena/', formData)
                        .then((response) => {
                            if (response.status === 200) {
                                setFormStatus('exito')
                                setEmail(response.data.correo_electronico)
                            }
                        })
                        .catch((error) => {
                            if (error.status === 404) {
                                setFormErrors((prevState) => {
                                    return {...prevState, ...{"rut": error.response.data.detail}}
                                })
                            } else {
                                setFormErrors((prevState) => {
                                    return {...prevState, ...{"general": "Tuvimos un error. Inténtelo de nuevo más tarde"}}
                                })
                            }
                        }))
                .catch(() => setFormErrors((prevState) => {
                        return {...prevState, ...{"general": "Tuvimos un error. Inténtelo de nuevo más tarde"}}
                    })
                )
        }

        setSubmitting(false)

    }

    const handleChange = event => {
        let name = event.target.name
        let value = event.target.value

        if (name === 'rut') {
            value = formatearRut(value)
        }
        setFormData((values => ({...values, [name]: value})))
    }


    if (formStatus === 'pendiente') {
        return (
            <>
                <h1>Recuperar Contraseña</h1>

                <p>Para recuperar su contraseña primero ingrese su rut.</p>
                <form className={"login-form"} onSubmit={handleSubmit}>
                    {formErrors.general ? <div className="error">{formErrors.general}</div> : null}
                    <label>
                        <strong>Rut</strong>
                        <input type="text" name="rut" value={formData.rut} onChange={handleChange}/>
                        {formErrors.rut && <div className="error">{formErrors.rut}</div>}
                    </label>
                    <button type="submit">Recuperar contraseña</button>
                </form>
            </>
        )
    }
    if (formStatus === 'exito') {
        return (
            <>
                <h1>Revise su correo electrónico</h1>
                <p>Le hemos enviado un correo electrónico su dirección <span className={"morado"} style={{fontWeight: "600"}}>{email}</span> con las instrucciones para recuperar su contraseña.</p>
            </>
        )
    }
}
