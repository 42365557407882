import React from 'react';
import FormularioCambiarContrasena from "../../../components/forms/usuarios/FormularioCambiarContrasena";

export default function PaginaAdminCambiarContrasena() {

    return (
        <div className={"pagina-cambiar-contrasena-usuario-wrapper"}>
            <div className={"formulario-wrapper"}>
                <FormularioCambiarContrasena/>
            </div>
        </div>
    )

}
