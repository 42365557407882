import React from 'react';
import FormularioEmprendedora from "../../../components/forms/emprendedoras/FormularioEmprendedora";

export default function PaginaCrearEmprendedora() {
    return (
        <div className={"pagina-crear-emprendedora-wrapper"}>
            <div className={"formulario-wrapper"}>
                <FormularioEmprendedora/>
            </div>
        </div>
    )

}
