import React, {useContext, useEffect, useState} from "react";
import './../form-styles.css'
import './formulario-capsula.css'
import {getTokenRequest, postTokenRequest, patchTokenRequest} from "../../../requests/requests";
import {FeedbackContext} from "../../../context/FeedbackContext";
import {useNavigate, useParams} from "react-router-dom";
import VideoField from "../fields/VideoField";
import Feedbacks from "../../feedback/Feedback";
import {Boton} from "../../boton/Botones";
import {validarURL} from "../validadores";

export default function FormularioCapsula({accion}) {
    const navigate = useNavigate()
    const id = useParams().id;

    const getCrearPrefixURL = 'admin/capsulas/detalle/'
    const postCrearURL = '/admin/capsulas/crear/'
    const patchEditarPrefixURL = '/admin/capsulas/editar/'

    const onSuccessRedirectPATH = '/admin/capsulas/todas'
    const onSuccessCreateFeedbackMessage = "Cápsula creada exitosamente"
    const onSuccessEditFeedbackMessage = "Cápsula editada exitosamente"


    const {feedbackList, pushFeedback} = useContext(FeedbackContext);
    const [loaded, setLoaded] = useState(false);

    const [waitingForResponse, setWaitingForResponse] = useState(false);
    const [progress, setProgress] = useState('0%');

    const [formData, setFormData] = useState({
        titulo: '',
        descripcion: '',
        archivo: '',
    })

    const [formErrors, setFormErrors] = useState({
        titulo: '',
        descripcion: '',
        archivo: '',
    })


    useEffect(() => {
            if (accion === 'editar') {
                getTokenRequest(`${getCrearPrefixURL}${id}/`).then((response) => {
                    setFormData(response.data);
                    setLoaded(true);
                }).catch(error => console.log(error))
            } else {
                setLoaded(true)
            }
        }, [accion, id]
    )


    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({...prevFormData, [name]: value}));
    };

    const handleFileChange = (event) => {
        event.preventDefault()
        let name = event.target.name;
        let file = event.target.files.length > 0 ? event.target.files[0] : ''
        setFormData((prevFormData) => ({...prevFormData, [name]: file}));
    }

    const handleUploadProgress = (event) => {
        setProgress(`${(event.progress * 100).toFixed(2)}%`)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let valido = true
        let errors = {
            titulo: '',
            descripcion: '',
            archivo: '',
        }

        if (!(formData.archivo instanceof File) && !(validarURL(formData.archivo).valido)) {
            errors.archivo = "Debe subir un video";
            valido = false
        }

        setFormErrors(errors)

        if (valido && !waitingForResponse) {
            setWaitingForResponse(true)
            let data = new FormData();
            data.append('titulo', formData.titulo.trim());
            data.append('descripcion', formData.descripcion.trim());
            if (formData.archivo instanceof File)
                data.append('archivo', formData.archivo);


            if (accion === 'editar') {
                await patchTokenRequest(`${patchEditarPrefixURL}${id}/`, data, {onUploadProgress: handleUploadProgress}).then((response) => {
                        if (response.status === 200) {
                            pushFeedback(onSuccessEditFeedbackMessage)
                            navigate(onSuccessRedirectPATH);
                        }
                    }
                ).catch(() => {
                    pushFeedback("Tuvimos un problema al editar la cápsula. Por favor revise el formulario.", 'error')
                });
            } else {
                await postTokenRequest(postCrearURL, data, {onUploadProgress: handleUploadProgress}).then((response) => {
                        if (response.status === 201) {
                            pushFeedback(onSuccessCreateFeedbackMessage)
                            navigate(onSuccessRedirectPATH);
                        }
                    }
                ).catch(() => {
                        pushFeedback("Tuvimos un problema al crear la cápsula. Por favor revise el formulario.", 'error')
                    }
                );
            }
        }
        setWaitingForResponse(false)
    }

    if (waitingForResponse)
        return (
            <div>
                <h1>Su cápsula se está {accion === 'editar' ? 'editando' : 'subiendo'}</h1>
                <div>Progreso: {progress}</div>
            </div>
        )

    if (loaded) {
        return (
            <>
                <Feedbacks feedbackList={feedbackList}/>
                {accion === 'editar' ?
                    <h1>Editar cápsula</h1> :
                    <><h1>Crear una cápsula</h1><p>Para crear una cápsula rellene los campos requeridos.</p></>}
                <form className={"formulario"} onSubmit={handleSubmit}>
                    <div className={'form-field'}>
                        <label htmlFor="fname"><span>Título:</span></label>
                        <input type="text" id="titulo" name="titulo" value={formData.titulo}
                               onChange={handleChange}/>
                    </div>
                    <div className={'form-field descripcion'}>
                        <label htmlFor="fname">Descripcion:</label>
                        <textarea id="descripcion" name="descripcion" value={formData.descripcion}
                                  onChange={handleChange}/>
                    </div>
                    <div className={'form-field'}>
                        <label htmlFor={"fname"}>Video: <span className={"rojo"}>*</span> </label>
                        <div className={"rojo"}>{formErrors.archivo}</div>
                        <VideoField src={formData.archivo} name="archivo" onChange={handleFileChange}></VideoField>
                    </div>
                    <div className={"formulario form-actions"}>
                        <Boton color={"boton-verde"} texto={"Enviar"} onClick={handleSubmit}/>
                    </div>
                </form>
            </>
        )
    }
    return null
}
