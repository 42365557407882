import React from "react";
import './botones.css'

export function Boton({texto, onClick, color}) {

    return (
        <button className={`boton ${color}`} onClick={onClick}>
            {texto}
        </button>
    )

}

export function BotonEmprendedora({id, texto, active, onClick}) {

    return (
        <button id={id} className={`boton boton-emprendedora ${active ? 'active' : ''}`} onClick={onClick}>
            {texto}
        </button>
    )
}
