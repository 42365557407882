import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../../context/FeedbackContext";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {deleteTokenRequest, getTokenRequest} from "../../../requests/requests";
import {Imagen} from "../../../components/imagenes/Imagenes";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import Feedbacks from "../../../components/feedback/Feedback";
import {EditarIcon, EliminarIcon} from "../../../components/imagenes/Iconos";
import {Boton} from "../../../components/boton/Botones";


export default function PaginaAdminEmprendedora() {
    const navigate = useNavigate();
    const {feedbackList, pushFeedback} = useContext(FeedbackContext);
    const [emprendedoras, setEmprendedoras] = useState([]);

    useEffect(() => {
        getTokenRequest(`admin/emprendedoras/todas/`).then((response) => {
            setEmprendedoras(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }, [setEmprendedoras]);


    const handleCrearClick = () => {
        navigate('/admin/emprendedoras/crear');
    }

    const handleEditarClick = (id) => {
        navigate(`/admin/emprendedoras/editar/${id}`);
    }

    const deleteEmprendedora = (id) => {
        deleteTokenRequest(`admin/emprendedoras/eliminar/${id}/`).then((response) => {
            if (response.status === 204) {
                setEmprendedoras(emprendedoras => emprendedoras.filter(emprendedora => emprendedora.id !== id));
                pushFeedback("La emprendedora ha sido eliminada exitosamente")
            }
        }).catch(error => {
            console.log(error)
        });
    }

    const handleEliminarClick = (id) => {
        Swal.fire({
            title: "¿Está seguro que desea continuar?",
            text: `Al eliminar esta emprendedora no podrá recuperarla`,
            icon: "warning",
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteEmprendedora(id)
            }
        })
    }

    return (<div className={"pagina-admin-emprendedoras-wrapper"}>
            <Feedbacks feedbackList={feedbackList}/>
            <div className={"flex space-between align-center"}>
                <h1>Todas las Emprendedoras</h1>
                <Boton color={"boton-azul"} texto={"Crear Emprendedora"} onClick={handleCrearClick}/>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Fotografía</TableCell>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emprendedoras.map((emprendedora, index) => (<TableRow
                                key={index}
                            >
                                <TableCell>
                                    <Imagen src={emprendedora.fotografia} width={"100%"} aspectRatio={"1/1"}
                                            objectFit={"cover"}
                                            style={{width: "70px"}}></Imagen>
                                </TableCell>
                                <TableCell>{emprendedora.nombres} {emprendedora.apellidos}</TableCell>
                                <TableCell>
                                    <div className={"flex"}>
                                        <EditarIcon onClick={() => handleEditarClick(emprendedora.id)}/>
                                        <EliminarIcon onClick={() => handleEliminarClick(emprendedora.id)}/>
                                    </div>
                                </TableCell>
                            </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>)
}
