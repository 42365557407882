import React, {useCallback, useEffect, useState} from 'react';
import './carrusel-inicio.css'
import {Imagen, LinkImagen} from "../../imagenes/Imagenes";
import {getRequest} from "../../../requests/requests";

export function CarruselInicio({className}) {
    const [imagenes, setImagenes] = useState([])

    useEffect(() => {
        getRequest('inicio/carrusel/todas/').then((response) => {
                setImagenes(response.data)
            }
        ).catch((error) => {
            console.log(error);
        })
    }, [setImagenes]);


    const [state, setState] = useState({
        active: 0,

        startForward: -1,
        endForward: -1,
        nextForward: -1,

        startBack: -1,
        endBack: -1,
        nextBack: -1,

        inTransition: 0,
    })

    function getPrevSlideIndex() {
        if (state.active === 0) {
            return imagenes.length - 1
        }
        return (state.active - 1)
    }

    const getNextSlideIndex = useCallback(() => {
        return (state.active + 1) % imagenes.length
    }, [imagenes.length, state.active])

    function setClass(index, comparacion, className) {
        if (index === comparacion)
            return " " + className
        return ""
    }

    function finishTransition() {
        setState((prevState) => ({
            ...prevState,
            startForward: -1,
            endForward: -1,
            nextForward: -1,

            startBack: -1,
            endBack: -1,
            nextBack: -1,

            inTransition: 0
        }))
    }

    const doTransitionNext = useCallback(next => {
        setState(prevState => ({
            ...prevState,
            startForward: prevState.active,
            endForward: next,
            active: next
        }))
        setTimeout(() => {
            finishTransition()
        }, 1000)
    }, [])

    const handleNext = useCallback((next) => {
        if (document.hasFocus() && !state.inTransition && imagenes.length > 1) {
            setState((prevState) => ({
                ...prevState,
                inTransition: 1,
                nextForward: next,
            }))
        }
    }, [imagenes.length, state.inTransition])

    useEffect(() => {
        if (state.nextForward !== -1) {
            setTimeout(() => doTransitionNext(state.nextForward), 20)
        }
    }, [doTransitionNext, state.nextForward]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (document.hasFocus() && !state.inTransition && imagenes.length > 1) {
                handleNext(getNextSlideIndex())
            }
        }, 5000);

        return () => clearInterval(timer);
    }, [getNextSlideIndex, handleNext, imagenes.length, state.inTransition]);


    function handlePrev(prev) {
        if (document.hasFocus() && !state.inTransition && imagenes.length > 1) {
            setState((prevState) => ({
                ...prevState,
                inTransition: 1,
                nextBack: prev,
            }))
        }
    }

    const doTransitionPrev = useCallback((prev) => {
        setState((prevState) => ({
            ...prevState,
            startBack: prevState.active,
            endBack: prev,
            active: prev
        }))
        setTimeout(() => {
            finishTransition()
        }, 1000)
    }, [])

    useEffect(() => {
        if (state.nextBack !== -1) {
            setTimeout(() => doTransitionPrev(state.nextBack), 20)
        }
    }, [doTransitionPrev, state.nextBack]);

    function handleClick(e, index) {
        if (index > state.active)
            handleNext(index)
        if (index < state.active)
            handlePrev(index)
    }


    if (imagenes.length)
        return (
            <div className={className}>
                <div className={"slider"}>
                    {imagenes.map((imagen, index) => {
                        if (imagen.url_redirect)
                            return <LinkImagen key={index}
                                               to={imagen.url_redirect}
                                               className={`carrusel-imagen${setClass(index, state.active, 'active')}${setClass((index), state.nextForward, 'carrusel-inicio-next-forward')}${setClass(index, state.startForward, 'carrusel-inicio-start-forward')}${setClass(index, state.endForward, 'carrusel-inicio-end-forward')}${setClass((index), state.nextBack, 'carrusel-inicio-next-back')}${setClass(index, state.startBack, 'carrusel-inicio-start-back')}${setClass(index, state.endBack, 'carrusel-inicio-end-back')}`}
                                               width={"100%"} height={"100%"} src={imagen.imagen}
                            />
                        else
                            return <Imagen key={index}
                                           className={`carrusel-imagen${setClass(index, state.active, 'active')}${setClass((index), state.nextForward, 'carrusel-inicio-next-forward')}${setClass(index, state.startForward, 'carrusel-inicio-start-forward')}${setClass(index, state.endForward, 'carrusel-inicio-end-forward')}${setClass((index), state.nextBack, 'carrusel-inicio-next-back')}${setClass(index, state.startBack, 'carrusel-inicio-start-back')}${setClass(index, state.endBack, 'carrusel-inicio-end-back')}`}
                                           width={"100%"} height={"100%"} src={imagen.imagen}
                            />

                    })}
                </div>
                <div className={"control-circles"}>
                    {imagenes.map((_, index) => {
                        return (
                            <span key={index}
                                  className={`control-circle ${index === state.active ? "control-circle-active" : ""}`}
                                  onClick={(e) => handleClick(e, index)}/>
                        )
                    })}
                </div>
                <button className="control-arrow-prev" onClick={() => handlePrev(getPrevSlideIndex())}>❮</button>
                <button className="control-arrox-next" onClick={() => handleNext(getNextSlideIndex())}>❯</button>

            </div>
        )
}
